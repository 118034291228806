import { useQuery } from "@tanstack/react-query";
import axios from "axios";

// Fetch slide state by clientId, projectId, and slide
export const fetchSlideState = async (clientId, projectId, slide) => {
  if (projectId === null || projectId === undefined) return {};
  const slideState = await axios.get(
    `/api/get-project-slide/${clientId}/${projectId}/${slide}/`,
    {
      withCredentials: true,
    },
  );

  return slideState.data;
};

// Custom hook to fetch slide state
export default function useSlideState(clientId, projectId, slide) {
  return useQuery({
    queryKey: ["slideState", clientId, projectId, slide],
    queryFn: () => fetchSlideState(clientId, projectId, slide),
    enabled:
      !!clientId && projectId !== null && projectId !== "" && slide > 0
        ? true
        : false,
  });
}
