import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useContext } from "react";
import { DashboardContext } from "../../../../DashboardContext/DashboardContextProvider";

const getPresignedUrl = async (data) => {
  const { clientId, projectId, idProp, fileName, fileType } = data;
  try {
    const res = await axios.post(
      `/api/get-slide9-file-url/${clientId}/${projectId}/${idProp}/`,
      {
        fileName,
        fileType,
      },
      { withCredentials: true },
    );

    return res;
  } catch (err) {
    console.error(err);
  }
};

export const useGetPresignedUrl = (setIsUploading) => {
  return useMutation({
    mutationFn: getPresignedUrl,
    onError: (err) => {
      console.error("PRESIGNED URL ERROR", err);
      setIsUploading(false);
    },
    onSuccess: (data) => console.log("PRESIGNED URL SUCCESS", data),
  });
};

const uploadToS3 = async (data) => {
  const { presignedPost, file, setProgress } = data;

  console.log("PRESIGNED POST: ", presignedPost);
  console.log("FILE: ", file);

  const formData = new FormData();

  Object.keys(presignedPost.fields).forEach((key) => {
    formData.append(key, presignedPost.fields[key]);
  });

  formData.append("file", file);

  const res = await axios.post(presignedPost.url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total,
      );
      // console.log("UPLOAD PROGRESS: ", percentCompleted);
      setProgress(() => percentCompleted);
    },
  });

  return res;
};

export const useUploadToS3 = (setIsUploading) => {
  return useMutation({
    mutationFn: uploadToS3,
    onError: (err) => {
      console.error("UPLOAD TO S3 ERROR: ", err);
      setIsUploading(false);
    },
    onSuccess: (data) => console.log("UPLOAD TO S3 SUCESS: ", data),
  });
};

const confirmFileUpload = async (data) => {
  const { clientId, projectId, idProp, filePath, fileName } = data;
  try {
    const res = await axios.post(
      `/api/confirm-file-upload/${clientId}/${projectId}/${idProp}/`,
      {
        filePath,
        fileName,
      },
      { withCredentials: true },
    );

    return res;
  } catch (err) {
    console.error(err);
  }
};

export const useConfirmFileUpload = (setIsLoading, setIsUploading) => {
  const { queryClient } = useContext(DashboardContext);
  return useMutation({
    mutationFn: confirmFileUpload,
    onError: (err) => {
      console.error("CONFIRM FILE UPLOAD ERROR: ", err);
    },
    onSuccess: (data) => {
      console.log("CONFIRM FILE UPLOAD SUCCESS: ", data.data);

      queryClient.invalidateQueries({
        queryKey: ["currentProject", data.data.clientId, data.data.projectId],
      });
      return data;
    },
    onSettled: () => {
      setIsLoading(false);
      setIsUploading(false);
    },
  });
};
