import { DataGridPro } from "@mui/x-data-grid-pro";
import React, { useContext, useEffect, useState } from "react";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";
import RadioGroupField from "../Inputs/RadioGroupField";
import TextField from "../Inputs/TextField.jsx";
import MapComponent from "../Map/NewMap.jsx";
import { FormContext } from "../context/FormProvider";
import { scrollToFirstRequiredField } from "../main-helper.js";
import useSetSlide from "./Hooks/setSlide";
import useInvalidInputs from "./Hooks/useInvalidInputs";
import "./styles/projectlocation.css";

// Map Instructions Component
const MapInstructions = () => {
  return (
    <div id="map-instructions">
      <h1 id="map-instructions-heading">Map & Pin Instructions</h1>
      <div className="instructions">
        <div className="instruction">
          <h3 className="instruction-name">Moving Around the Map</h3>
          <p className="map-instructions">
            To move around the map, click and drag the map or pressing the full
            screen button on the top right and press an arrow key in the
            direction you want to move.
          </p>
        </div>
        <div className="instruction">
          <h3 className="instruction-name">Zoom</h3>
          <p className="map-instructions">
            To can zoom in and out of the map by scrolling up and down with your
            mouse or by pressing the plus and minus buttons on the bottom right
            of the map.
          </p>
        </div>
        <div className="instruction">
          <h3 className="instruction-name">
            Adding/Removing a Pin (Non-Linear)
          </h3>
          <p className="map-instructions">
            Click on the map where you would like to add the pin. Click on the
            pin to remove it from the map. You can move the pin by either
            clicking and removing the pin and then clicking again on the new
            location or by selecting a new area of the map to place the pin.
          </p>
        </div>
        <div className="instruction">
          <h3 className="instruction-name">Adding/Removing a Pin (Linear)</h3>
          <p className="map-instructions">
            Click on the map where you would like to add the starting pin. Click
            again anywhere on the map to add the ending pin. Click either pin to
            remove it from the map. You can only move a specific pin by first
            clicking and removing the pin and then clicking again on the new
            location.
          </p>
        </div>
        <div className="instruction">
          <h3 className="instruction-name">Incorrect Address</h3>
          <p className="map-instructions">
            If the pin provides an incorrect address, change the address in the
            field provided below the map.
          </p>
        </div>
      </div>
    </div>
  );
};

// Slide 6: Project Location
const ProjectLocation = ({ setSlideStatus, slideState, formStateLoaded }) => {
  const {
    slide1,
    slide6,
    setSlide6,
    setIsLoading,
    requestStatus,
    populateInvalidInputs,
    getInvalidData,
    getInputMessage,
    disabledInputCheck,
    disableAll,
  } = useContext(FormContext);

  const { clientId, projectId } = useContext(DashboardContext);

  const [markerCount, setMarkerCount] = useState(0);
  const [disabledInputs, setDisabledInputs] = useState([]);
  const [showInputs, setShowInputs] = useState(false);
  const markerSize = 30;
  const [counties, setCounties] = useState([
    "Apache",
    "Cochise",
    "Coconino",
    "Gila",
    "Graham",
    "Greenlee",
    "La Paz",
    "Maricopa",
    "Mohave",
    "Navajo",
    "Pima",
    "Pinal",
    "Santa Cruz",
    "Yavapai",
    "Yuma",
  ]);
  const [cities, setCities] = useState(
    [
      "Apache Junction",
      "Aguila",
      "Ajo",
      "Avondale",
      "Buckeye",
      "Carefree",
      "Casa Grande",
      "Cave Creek",
      "Chandler",
      "El Mirage",
      "Flagstaff",
      "Fountain Hills",
      "Glendale",
      "Gila Bend",
      "Gilbert",
      "Goodyear",
      "Guadalupe",
      "Litchfield Park",
      "Mesa",
      "Paradise Valley",
      "Peoria",
      "Phoenix",
      "Prescott",
      "Scottsdale",
      "Sun City",
      "Sun City West",
      "Surprise",
      "Queen Creek",
      "Tempe",
      "Tolleson",
      "Tucson",
      "Wickenburg",
      "Wikieup",
      "Youngtown",
      "Yuma",
    ].sort(),
  );

  useEffect(() => {
    scrollToFirstRequiredField(slide6);
  }, []);

  // Update Slide Logic
  const slide6Mutation = useSetSlide(
    clientId,
    projectId,
    6,
    setIsLoading,
    setSlideStatus,
  );

  // Get Invalid Inputs
  const invalidInputsData = useInvalidInputs(
    clientId,
    projectId,
    requestStatus,
  );

  // Populate Invalid Inputs
  useEffect(() => {
    populateInvalidInputs(
      invalidInputsData,
      disabledInputs,
      setDisabledInputs,
      "6",
    );
  }, [invalidInputsData.data, invalidInputsData.isFetched]);

  const columns = [
    {
      field: "marker",
      headerName: "Marker",
      pinnable: true,
      flex: 0.6,
      hideable: false,
      resizable: false,
      headerAlign: "center",
      align: "center",
      headerClassName: "location-headers",
      cellClassName: "location-cells",
      disabledColumnMenu: true,
      sortable: false,
    },
    {
      field: "address",
      headerName: "Address",
      pinnable: false,
      flex: 1.5,
      hideable: false,
      resizable: false,
      headerAlign: "center",
      align: "center",
      headerClassName: "location-headers",
      cellClassName: "location-cells",
      disabledColumnMenu: true,
      sortable: false,
    },
    {
      field: "county",
      headerName: "County",
      pinnable: false,
      flex: 1,
      hideable: false,
      resizable: false,
      headerAlign: "center",
      align: "center",
      headerClassName: "location-headers",
      cellClassName: "location-cells",
      disabledColumnMenu: true,
      sortable: false,
    },
    {
      field: "city",
      headerName: "City",
      pinnable: false,
      flex: 1.1,
      hideable: false,
      resizable: false,
      headerAlign: "center",
      align: "center",
      headerClassName: "location-headers",
      cellClassName: "location-cells",
      disabledColumnMenu: true,
      sortable: false,
    },
    {
      field: "zip",
      headerName: "Zip",
      pinnable: false,
      flex: 0.6,
      hideable: false,
      resizable: false,
      headerAlign: "center",
      align: "center",
      headerClassName: "location-headers",
      cellClassName: "location-cells",
      disabledColumnMenu: true,
      sortable: false,
    },
    {
      field: "latitude",
      headerName: "Latitude",
      pinnable: false,
      flex: 0.8,
      hideable: false,
      resizable: false,
      headerAlign: "center",
      align: "center",
      headerClassName: "location-headers",
      cellClassName: "location-cells",
      disabledColumnMenu: true,
      sortable: false,
    },
    {
      field: "longitude",
      headerName: "Longitude",
      pinnable: false,
      flex: 0.8,
      hideable: false,
      resizable: false,
      headerAlign: "center",
      align: "center",
      headerClassName: "location-headers",
      cellClassName: "location-cells",
      disabledColumnMenu: true,
      sortable: false,
    },
  ];
  // Create rows state
  const [rows, setRows] = useState([]);

  // Add data to rows state
  useEffect(() => {
    if (slide6.isLinear) {
      const row1 = {
        id: "marker-1",
        marker: "Start",
        address: slide6.pAdd || slide6.marker.details[0],
        county: slide6.pCounty || slide6.marker.details[1],
        city: slide6.pCity || slide6.marker.details[2],
        zip: slide6.pZip || slide6.marker.details[3],
        latitude: slide6.bLat || slide6.marker.position[0],
        longitude: slide6.bLng || slide6.marker.position[1],
      };
      const row2 = {
        id: "marker-2",
        marker: "End",
        address: slide6.marker2.details[0],
        county: slide6.marker2.details[1],
        city: slide6.marker2.details[2],
        zip: slide6.marker2.details[3],
        latitude: slide6.eLat || slide6.marker2.position[0],
        longitude: slide6.eLng || slide6.marker2.position[1],
      };

      setRows([row1, row2]);
    } else {
      const row = {
        id: "marker-1",
        marker: "Marker",
        address: slide6.pAdd || slide6.marker.details[0],
        county: slide6.pCounty || slide6.marker.details[1],
        city: slide6.pCity || slide6.marker.details[2],
        zip: slide6.pZip || slide6.marker.details[3],
        latitude: slide6.bLat || slide6.marker.position[0],
        longitude: slide6.bLng || slide6.marker.position[1],
      };

      setRows([row]);
    }
  }, [slide6]);

  return (
    <>
      {formStateLoaded ? (
        <>
          <RadioGroupField
            label={"Is this a Linear Project?"}
            idProp={"isLinear"}
            val={slide6.isLinear}
            state={slide6}
            setState={setSlide6}
            debouncedSave={slide6Mutation}
            setIsLoading={setIsLoading}
            disableInput={disabledInputCheck("isLinear", disabledInputs)}
          />
          <MapInstructions />
          <h3 id="map-heading">
            {slide6.isLinear === false
              ? "Select the map at the closest centermost point of the site location"
              : "Select the estimated beginning and ending points of the site"}
          </h3>
          <MapComponent
            projectName={slide1.projectName}
            slide6={slide6}
            setSlide6={setSlide6}
            requestStatus={requestStatus}
            disableAll={disableAll}
            debouncedSave={slide6Mutation}
            setIsLoading={setIsLoading}
          />
          <div id="location-inputs">
            {!slide6.pAdd ||
            !slide6.pCity ||
            !slide6.pCounty ||
            !slide6.pZip ? (
              <p id="location-p">Please Enter the Following Information</p>
            ) : null}
            <TextField
              label={"Closest Street Address"}
              idProp={"pAdd"}
              val={slide6.pAdd}
              state={slide6}
              setState={setSlide6}
              debouncedSave={slide6Mutation}
              setIsLoading={setIsLoading}
              req={slide6.pAdd === "" || slide6.pAdd.length < 10}
              type="add"
              disabledInput={disabledInputCheck("pAdd", disabledInputs)}
              disabledInputMessage={
                disabledInputCheck("pAdd", disabledInputs) === false
                  ? getInputMessage("pAdd", invalidInputsData, "6")
                  : ""
              }
              invalidData={getInvalidData("pAdd", invalidInputsData, "6")}
            />
            <TextField
              label={"City"}
              idProp={"pCity"}
              val={slide6.pCity}
              state={slide6}
              setState={setSlide6}
              debouncedSave={slide6Mutation}
              setIsLoading={setIsLoading}
              autoData={cities}
              req={
                slide6.pCity === "" || cities.includes(slide6.pCity) === false
              }
              disabledInput={disabledInputCheck("pCity", disabledInputs)}
              disabledInputMessage={
                disabledInputCheck("pCity", disabledInputs) === false
                  ? getInputMessage("pCity", invalidInputsData, "6")
                  : ""
              }
              invalidData={getInvalidData("pCity", invalidInputsData, "6")}
            />
            <TextField
              label={"Zip"}
              idProp={"pZip"}
              val={slide6.pZip}
              state={slide6}
              setState={setSlide6}
              debouncedSave={slide6Mutation}
              setIsLoading={setIsLoading}
              req={
                slide6.pZip === "" ||
                slide6.pZip.length < 5 ||
                +slide6.pZip < 85001 ||
                +slide6.pZip > 86556
              }
              disabledInput={disabledInputCheck("pZip", disabledInputs)}
              disabledInputMessage={
                disabledInputCheck("pZip", disabledInputs) === false
                  ? getInputMessage("pZip", invalidInputsData, "6")
                  : ""
              }
              invalidData={getInvalidData("pZip", invalidInputsData, "6")}
            />
            <TextField
              label={"County"}
              idProp={"pCounty"}
              val={slide6.pCounty}
              state={slide6}
              setState={setSlide6}
              debouncedSave={slide6Mutation}
              setIsLoading={setIsLoading}
              autoData={counties}
              req={
                slide6.pCounty === "" ||
                counties.includes(slide6.pCounty) === false
              }
              disabledInput={disabledInputCheck("pCounty", disabledInputs)}
              disabledInputMessage={
                disabledInputCheck("pCounty", disabledInputs) === false
                  ? getInputMessage("pCounty", invalidInputsData, "6")
                  : ""
              }
              invalidData={getInvalidData("pCounty", invalidInputsData, "6")}
            />
          </div>
          <div id="location-table-container">
            <DataGridPro
              id="location-data-grid"
              className="location-data-grid"
              key={`${projectId}-location-table`}
              rows={rows.length > 0 ? rows : []}
              columns={columns}
              checkboxSelection={false}
              disableColumnMenu={true}
              disableColumnResize={true}
              disableColumnReorder={true}
              disableColumnSorting={true}
              pinnedColumns={{ left: ["marker"] }}
              density="compact"
              classes={{
                columnHeader: "location-column-header",
                columnHeaders: "location-column-headers",
                columnHeadersInner: "location-column-headers-inner",
                columnHeaderTitle: "location-column-header-title",
                columnHeaderTitleContainer:
                  "location-column-header-title-container",
                cell: "location-cell",
                cellContent: "location-cell-content",
                row: "location-row",
                virtualScrollerRenderZone: "location-render-zone",
                pinnedColumns: "location-pinned-columns",
                pinnedColumnHeaders: "location-pinned-columns-headers",
                columnSeparator: "location-column-separator",
                columnHeaderDraggableContainer: "location-draggable-container",
              }}
              sx={[
                {
                  boxShadow: 6,
                  border: 3,
                  borderColor: "rgb(217, 237, 245)",
                  fontFamily: "'Roboto', sans-serif",
                  backgroundColor: "rgb(57, 136, 168)",
                  color: "rgb(240, 240, 240)",
                  width: "95%",
                },
              ]}
              showColumnBorders={true}
              disableRowSelectionOnClick={true}
              columnHeaderHeight={55}
              getRowHeight={() => 45}
              showCellVerticalBorders={true}
              hideFooter={true}
            />
          </div>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};

export default ProjectLocation;
