import Button from "@mui/material/Button";
import React, { useContext, useEffect, useState } from "react";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";
import {
  isValidEmail,
  isValidName,
  isValidPhoneNumber,
} from "../Helper/formbtnhelper";
import DateField from "../Inputs/DateField";
import EmailField from "../Inputs/EmailField";
import PhoneField from "../Inputs/PhoneField";
import RadioField from "../Inputs/RadioField";
import RadioGroupField from "../Inputs/RadioGroupField";
import SliderField from "../Inputs/SliderField";
import TextField from "../Inputs/TextField";
import TitleField from "../Inputs/TitleField";
import { FormContext } from "../context/FormProvider";
import { scrollToFirstRequiredField } from "../main-helper";
import "../styles/form-container.css";
import useSetSlide from "./Hooks/setSlide";
import useInvalidInputs from "./Hooks/useInvalidInputs";
import "./styles/swppp-services.css";

// Slide 2: SWPPP Services
const SwpppServices = ({ setSlideStatus, slideState, formStateLoaded }) => {
  const [disabledInputs, setDisabledInputs] = useState([]);
  const [totalRecipients, setTotalRecipients] = useState(1);
  const [disableAddBtn, setDisableAddBtn] = useState(false);
  const {
    slide1,
    slide2,
    setSlide2,
    isLoading,
    setIsLoading,
    requestStatus,
    names,
    phoneNumbers,
    emails,
    titles,
    companies,
    predictiveTextFetched,
    activeUserId,
    populateInvalidInputs,
    getInvalidData,
    getInputMessage,
    disabledInputCheck,
    disableAll,
  } = useContext(FormContext);

  const { clientId, projectId, userId } = useContext(DashboardContext);

  // On Mount, the page should scroll to the top
  useEffect(() => {
    scrollToFirstRequiredField(slide2);
  }, []);

  // Update Slide State
  const slide2Mutation = useSetSlide(
    clientId,
    projectId,
    2,
    setIsLoading,
    setSlideStatus,
  );

  // On Mount, check if onNativeLand is true, if so, set submittingToRegAgency to true
  useEffect(() => {
    if (slide1.onNativeLand && slide2.submittingToRegAgency === false) {
      let newState = {
        ...slide2,
        submittingToRegAgency: true,
      };
      setSlide2(newState);
      slide2Mutation.mutate(newState);
    }
  }, []);

  // On state loaded, count the total amount of inspection recipients
  useEffect(() => {
    if (formStateLoaded && slideState) {
      let recipients = [
        slideState.inspectionRecipient1,
        slideState.inspectionRecipient2,
        slideState.inspectionRecipient3,
        slideState.inspectionRecipient4,
      ];
      recipients = recipients.filter((recipient) => recipient !== "");
      setTotalRecipients(() => recipients.length);
    }
  }, [slideState, formStateLoaded]);

  // ADD/REMOVE RECIPIENT BUTTON EVENT HANDLER LOGIC
  const addRecipient = () => {
    if (totalRecipients < 4) {
      setTotalRecipients(() => totalRecipients + 1);
    }
  };

  // Function to remove a recipient
  const removeRecipient = () => {
    if (totalRecipients > 1) {
      setTotalRecipients(totalRecipients - 1);
    }
    let idx = totalRecipients;
    let newState = {
      ...slide2,
      [`inspectionRecipient${idx}`]: "",
      [`inspectionRecipient${idx}Email`]: "",
      [`inspectionRecipient${idx}Title`]: "",
    };
    setSlide2(newState);
    slide2Mutation.mutate(newState);
  };

  // Function to set the disableAddBtn state to enable/disable the Add Recipient button
  function disableAddRecipient() {
    if (requestStatus === "P" || requestStatus === "RV") return true;
    if (requestStatus === "IP" && activeUserId !== userId) {
      return true;
    }
    if (totalRecipients === 4) {
      return true;
    }

    if (isLoading) {
      return true;
    }

    if (totalRecipients <= 1) {
      if (
        slide2.inspectionRecipient1 === "" ||
        slide2.inspectionRecipient1Email === ""
      ) {
        return true;
      }
    } else if (totalRecipients <= 2) {
      if (
        slide2.inspectionRecipient2 === "" ||
        slide2.inspectionRecipient2Email === ""
      ) {
        return true;
      }
    } else if (totalRecipients <= 3) {
      if (
        slide2.inspectionRecipient3 === "" ||
        slide2.inspectionRecipient3Email === ""
      ) {
        return true;
      }
    } else if (totalRecipients < 4) {
      if (
        slide2.inspectionRecipient4 === "" ||
        slide2.inspectionRecipient4Email === ""
      ) {
        return true;
      }
    }
    return false;
  }

  useEffect(() => {
    setDisableAddBtn(() => disableAddRecipient());
  }, [isLoading]);

  // Get Invalid Inputs
  const invalidInputsData = useInvalidInputs(
    clientId,
    projectId,
    requestStatus,
  );

  // Populate Invalid Inputs
  useEffect(() => {
    populateInvalidInputs(
      invalidInputsData,
      disabledInputs,
      setDisabledInputs,
      "2",
    );
  }, [invalidInputsData.data, invalidInputsData.isFetched]);

  return (
    <>
      {formStateLoaded ? (
        <>
          {slide2.swpppServicesRequest ? (
            <>
              {+slide1.distAcr >= 1 ? (
                <RadioGroupField
                  idProp="noiRequest"
                  label="Argus to Prepare NOI"
                  state={slide2}
                  setState={setSlide2}
                  val={slide2.noiRequest}
                  debouncedSave={slide2Mutation}
                  setIsLoading={setIsLoading}
                  disabledInput={disabledInputCheck(
                    "noiRequest",
                    disabledInputs,
                  )}
                />
              ) : null}
              <RadioGroupField
                idProp="submittingToRegAgency"
                label="Submitting SWPPPP to Regulatory Agency"
                state={slide2}
                setState={setSlide2}
                val={slide2.submittingToRegAgency}
                debouncedSave={slide2Mutation}
                setIsLoading={setIsLoading}
                disabledInput={disabledInputCheck(
                  "submittingToRegAgency",
                  disabledInputs,
                )}
              />
              {slide2.submittingToRegAgency ? (
                <>
                  <DateField
                    idProp="regAgencySubmitDate"
                    label="Estimated Submission Date"
                    state={slide2}
                    setState={setSlide2}
                    val={
                      slide2.submittingToRegAgency
                        ? slide2.regAgencySubmitDate
                        : ""
                    }
                    debouncedSave={slide2Mutation}
                    setIsLoading={setIsLoading}
                    req={
                      slide2.submittingToRegAgency === true &&
                      slide2.regAgencySubmitDate === ""
                    }
                    disabledInput={disabledInputCheck(
                      "regAgencySubmitDate",
                      disabledInputs,
                    )}
                    disabledInputMessage={
                      disabledInputCheck(
                        "regAgencySubmitDate",
                        disabledInputs,
                      ) === false
                        ? getInputMessage(
                            "regAgencySubmitDate",
                            invalidInputsData,
                            "2",
                          )
                        : ""
                    }
                    invalidData={getInvalidData(
                      "regAgencySubmitDate",
                      invalidInputsData,
                      "2",
                    )}
                  />
                  <TextField
                    idProp="agencyName"
                    label="Agency Name"
                    state={slide2}
                    type="regAgency"
                    setState={setSlide2}
                    val={slide2.agencyName}
                    debouncedSave={slide2Mutation}
                    setIsLoading={setIsLoading}
                    autoData={predictiveTextFetched ? companies : []}
                    disabledInput={disabledInputCheck(
                      "agencyName",
                      disabledInputs,
                    )}
                    disabledInputMessage={
                      disabledInputCheck("agencyName", disabledInputs) === false
                        ? getInputMessage("agencyName", invalidInputsData, "2")
                        : ""
                    }
                    invalidData={getInvalidData(
                      "agencyName",
                      invalidInputsData,
                      "2",
                    )}
                  />
                </>
              ) : null}
              {+slide1.distAcr >= 1 ? (
                <>
                  {!slide1.onNativeLand ? (
                    <>
                      <RadioGroupField
                        idProp="activeMyDeq"
                        label="Is there an Active myDEQ Account"
                        state={slide2}
                        setState={setSlide2}
                        val={slide2.activeMyDeq}
                        debouncedSave={slide2Mutation}
                        setIsLoading={setIsLoading}
                        disabledInput={disabledInputCheck(
                          "activeMyDeq",
                          disabledInputs,
                        )}
                      />
                      {slide2.activeMyDeq ? (
                        <div id="myDeqInstructions">
                          <p className="myDeq-p">
                            You can now add Argus Construction Services to your
                            myDEQ account as a DATA ENTRY USER so that we may
                            prepare your NOI for you. We will then save the
                            completed application to your account so that you
                            can log in, approve, pay for, and print your NOI
                            permit.
                          </p>
                          <p className="myDeq-p">
                            To add Argus Construction Services as a DATA ENTRY
                            USER on your account, please do the following:
                          </p>
                          <ol id="myDeqOL">
                            <li className="myDeq-li">
                              Go to{" "}
                              <a
                                href="https://www.azdeq.gov/mydeq/home"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                www.azdeq.gov/mydeq/home
                              </a>
                            </li>
                            <li className="myDeq-li">
                              Log onto your myDEQ account
                            </li>
                            <li className="myDeq-li">
                              Click on the “mySETTINGS” tab at the top right of
                              the screen
                            </li>
                            <li className="myDeq-li">
                              Click on the “myUSERS” tab in the middle of the
                              screen
                            </li>
                            <li className="myDeq-li">
                              Click on the “ADD A USER” button
                            </li>
                            <li className="myDeq-li">
                              Add Robyn Bennett - robyn@arguscs.com
                            </li>
                            <li className="myDeq-li">
                              {
                                "Please select Submitter and/or 'Data Entry User' as the role"
                              }
                            </li>
                          </ol>
                          <p className="myDeq-p">
                            If you are not the “Responsible Corporate Officer”
                            for your company, please forward this message to the
                            person who created your myDEQ account so that they
                            may add Argus Construction Services/Robyn Bennett as
                            a Data Entry User.
                          </p>
                          <p className="myDeq-p important-text">
                            If you have any questions, please call Robyn at
                            602-237-5937 for assistance.
                          </p>
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <>
                      <RadioGroupField
                        idProp="activeCDX"
                        label='Is there an Active "cdx.epa.gov" Account'
                        state={slide2}
                        setState={setSlide2}
                        val={slide2.activeCDX}
                        debouncedSave={slide2Mutation}
                        setIsLoading={setIsLoading}
                        disabledInput={disabledInputCheck(
                          "activeCDX",
                          disabledInputs,
                        )}
                      />
                      {slide2.activeCDX ? (
                        <>
                          <TextField
                            idProp="cdxAccountManager"
                            label="Account Manager Name"
                            state={slide2}
                            setState={setSlide2}
                            val={slide2.cdxAccountManager}
                            debouncedSave={slide2Mutation}
                            setIsLoading={setIsLoading}
                            autoData={predictiveTextFetched ? names : []}
                            type="name"
                            req={
                              slide2.activeCDX === true &&
                              slide2.cdxAccountManager === ""
                            }
                            disabledInput={disabledInputCheck(
                              "cdxAccountManager",
                              disabledInputs,
                            )}
                            disabledInputMessage={
                              disabledInputCheck(
                                "cdxAccountManager",
                                disabledInputs,
                              ) === false
                                ? getInputMessage(
                                    "cdxAccountManager",
                                    invalidInputsData,
                                    "2",
                                  )
                                : ""
                            }
                            invalidData={getInvalidData(
                              "cdxAccountManager",
                              invalidInputsData,
                              "2",
                            )}
                          />
                          <TextField
                            idProp="cdxUserId"
                            label="CDX User ID #"
                            state={slide2}
                            setState={setSlide2}
                            val={slide2.cdxUserId}
                            debouncedSave={slide2Mutation}
                            setIsLoading={setIsLoading}
                            req={
                              slide2.activeCDX === true &&
                              slide2.cdxUserId === ""
                            }
                            disabledInput={disabledInputCheck(
                              "cdxUserId",
                              disabledInputs,
                            )}
                            disabledInputMessage={
                              disabledInputCheck(
                                "cdxUserId",
                                disabledInputs,
                              ) === false
                                ? getInputMessage(
                                    "cdxUserId",
                                    invalidInputsData,
                                    "2",
                                  )
                                : ""
                            }
                            invalidData={getInvalidData(
                              "cdxUserId",
                              invalidInputsData,
                              "2",
                            )}
                          />
                        </>
                      ) : (
                        <>
                          <span id="cdx-span">
                            Follow the instructions in the CDX{" "}
                            <a
                              id="cdx-link"
                              href="https://cdx.epa.gov/About/UserGuide"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              User Guide
                            </a>{" "}
                            to learn how to register.
                          </span>
                        </>
                      )}
                    </>
                  )}
                </>
              ) : null}
              <SliderField
                idProp="sNum"
                label="Number of SWPPP Manuals Requested"
                state={slide2}
                setState={setSlide2}
                val={slide2.sNum}
                debouncedSave={slide2Mutation}
                setIsLoading={setIsLoading}
                disabledInput={disabledInputCheck("sNum", disabledInputs)}
                disabledInputMessage={
                  disabledInputCheck("sNum", disabledInputs) === false
                    ? getInputMessage("sNum", invalidInputsData, "2")
                    : ""
                }
                invalidData={getInvalidData("sNum", invalidInputsData, "2")}
              />
              <RadioGroupField
                idProp="inspectionRequest"
                label={`Argus to preform ${
                  +slide1.distAcr >= 1 ? "SWPPP" : "SWMP"
                } Inspections`}
                state={slide2}
                setState={setSlide2}
                val={slide2.inspectionRequest}
                debouncedSave={slide2Mutation}
                setIsLoading={setIsLoading}
                disabledInput={disabledInputCheck(
                  "inspectionRequest",
                  disabledInputs,
                )}
              />
              {slide2.inspectionRequest ? (
                <>
                  <p id="recipient-info">
                    {
                      "Please provide the contact information of the individual(s) who will need to receive the inspection reports (1 recipient minimum)"
                    }
                  </p>
                  <TextField
                    idProp="inspectionRecipient1"
                    label="Recipient's Name"
                    state={slide2}
                    setState={setSlide2}
                    val={slide2.inspectionRecipient1}
                    req={
                      slide2.inspectionRequest &&
                      !isValidName(slide2.inspectionRecipient1, false)
                    }
                    debouncedSave={slide2Mutation}
                    setIsLoading={setIsLoading}
                    autoData={predictiveTextFetched ? names : []}
                    type="name"
                    disabledInput={disabledInputCheck(
                      "inspectionRecipient1",
                      disabledInputs,
                    )}
                    disabledInputMessage={
                      disabledInputCheck(
                        "inspectionRecipient1",
                        disabledInputs,
                      ) === false
                        ? getInputMessage(
                            "inspectionRecipient1",
                            invalidInputsData,
                            "2",
                          )
                        : ""
                    }
                    invalidData={getInvalidData(
                      "inspectionRecipient1",
                      invalidInputsData,
                      "2",
                    )}
                  />
                  <TitleField
                    idProp="inspectionRecipient1Title"
                    label="Recipient's Title"
                    state={slide2}
                    setState={setSlide2}
                    val={slide2.inspectionRecipient1Title}
                    req={
                      slide2.inspectionRequest &&
                      slide2.inspectionRecipient1Title === ""
                    }
                    debouncedSave={slide2Mutation}
                    setIsLoading={setIsLoading}
                    autoData={predictiveTextFetched ? titles : []}
                    disabledInput={disabledInputCheck(
                      "inspectionRecipient1Title",
                      disabledInputs,
                    )}
                    disabledInputMessage={
                      disabledInputCheck(
                        "inspectionRecipient1Title",
                        disabledInputs,
                      ) === false
                        ? getInputMessage(
                            "inspectionRecipient1Title",
                            invalidInputsData,
                            "2",
                          )
                        : ""
                    }
                    invalidData={getInvalidData(
                      "inspectionRecipient1Title",
                      invalidInputsData,
                      "2",
                    )}
                  />
                  <EmailField
                    idProp="inspectionRecipient1Email"
                    label="Recipient's Email"
                    state={slide2}
                    setState={setSlide2}
                    val={slide2.inspectionRecipient1Email}
                    req={
                      slide2.inspectionRequest &&
                      !isValidEmail(slide2.inspectionRecipient1Email, false)
                    }
                    debouncedSave={slide2Mutation}
                    setIsLoading={setIsLoading}
                    autoData={predictiveTextFetched ? emails : []}
                    disabledInput={disabledInputCheck(
                      "inspectionRecipient1Email",
                      disabledInputs,
                    )}
                    disabledInputMessage={
                      disabledInputCheck(
                        "inspectionRecipient1Email",
                        disabledInputs,
                      ) === false
                        ? getInputMessage(
                            "inspectionRecipient1Email",
                            invalidInputsData,
                            "2",
                          )
                        : ""
                    }
                    invalidData={getInvalidData(
                      "inspectionRecipient1Email",
                      invalidInputsData,
                      "2",
                    )}
                  />
                  {totalRecipients > 1 ? (
                    <>
                      <TextField
                        idProp="inspectionRecipient2"
                        label="Recipient's Name"
                        state={slide2}
                        setState={setSlide2}
                        val={slide2.inspectionRecipient2}
                        debouncedSave={slide2Mutation}
                        setIsLoading={setIsLoading}
                        autoData={predictiveTextFetched ? names : []}
                        req={!isValidName(slide2.inspectionRecipient2)}
                        type="name"
                        disabledInput={disabledInputCheck(
                          "inspectionRecipient2",
                          disabledInputs,
                        )}
                        disabledInputMessage={
                          disabledInputCheck(
                            "inspectionRecipient2",
                            disabledInputs,
                          ) === false
                            ? getInputMessage(
                                "inspectionRecipient2",
                                invalidInputsData,
                                "2",
                              )
                            : ""
                        }
                        invalidData={getInvalidData(
                          "inspectionRecipient2",
                          invalidInputsData,
                          "2",
                        )}
                      />
                      <TitleField
                        idProp="inspectionRecipient2Title"
                        label="Recipient's Title"
                        state={slide2}
                        setState={setSlide2}
                        val={slide2.inspectionRecipient2Title}
                        debouncedSave={slide2Mutation}
                        setIsLoading={setIsLoading}
                        autoData={predictiveTextFetched ? titles : []}
                        type="title"
                        disabledInput={disabledInputCheck(
                          "inspectionRecipient2Title",
                          disabledInputs,
                        )}
                        disabledInputMessage={
                          disabledInputCheck(
                            "inspectionRecipient2Title",
                            disabledInputs,
                          ) === false
                            ? getInputMessage(
                                "inspectionRecipient2Title",
                                invalidInputsData,
                                "2",
                              )
                            : ""
                        }
                        invalidData={getInvalidData(
                          "inspectionRecipient2Title",
                          invalidInputsData,
                          "2",
                        )}
                      />
                      <EmailField
                        idProp="inspectionRecipient2Email"
                        label="Recipient's Email"
                        state={slide2}
                        setState={setSlide2}
                        val={slide2.inspectionRecipient2Email}
                        debouncedSave={slide2Mutation}
                        setIsLoading={setIsLoading}
                        autoData={predictiveTextFetched ? emails : []}
                        req={!isValidEmail(slide2.inspectionRecipient2Email)}
                        disabledInput={disabledInputCheck(
                          "inspectionRecipient2Email",
                          disabledInputs,
                        )}
                        disabledInputMessage={
                          disabledInputCheck(
                            "inspectionRecipient2Email",
                            disabledInputs,
                          ) === false
                            ? getInputMessage(
                                "inspectionRecipient2Email",
                                invalidInputsData,
                                "2",
                              )
                            : ""
                        }
                        invalidData={getInvalidData(
                          "inspectionRecipient2Email",
                          invalidInputsData,
                          "2",
                        )}
                      />
                    </>
                  ) : null}
                  {totalRecipients > 2 ? (
                    <>
                      <TextField
                        idProp="inspectionRecipient3"
                        label="Recipient's Name"
                        state={slide2}
                        setState={setSlide2}
                        val={slide2.inspectionRecipient3}
                        debouncedSave={slide2Mutation}
                        setIsLoading={setIsLoading}
                        autoData={predictiveTextFetched ? names : []}
                        req={!isValidName(slide2.inspectionRecipient3)}
                        type="name"
                        disabledInput={disabledInputCheck(
                          "inspectionRecipient3",
                          disabledInputs,
                        )}
                        disabledInputMessage={
                          disabledInputCheck(
                            "inspectionRecipient3",
                            disabledInputs,
                          ) === false
                            ? getInputMessage(
                                "inspectionRecipient3",
                                invalidInputsData,
                                "2",
                              )
                            : ""
                        }
                        invalidData={getInvalidData(
                          "inspectionRecipient3",
                          invalidInputsData,
                          "2",
                        )}
                      />
                      <TitleField
                        idProp="inspectionRecipient3Title"
                        label="Recipient's Title"
                        state={slide2}
                        setState={setSlide2}
                        val={slide2.inspectionRecipient3Title}
                        debouncedSave={slide2Mutation}
                        setIsLoading={setIsLoading}
                        autoData={predictiveTextFetched ? titles : []}
                        type="title"
                        disabledInput={disabledInputCheck(
                          "inspectionRecipient3Title",
                          disabledInputs,
                        )}
                        disabledInputMessage={
                          disabledInputCheck(
                            "inspectionRecipient3Title",
                            disabledInputs,
                          ) === false
                            ? getInputMessage(
                                "inspectionRecipient3Title",
                                invalidInputsData,
                                "2",
                              )
                            : ""
                        }
                        invalidData={getInvalidData(
                          "inspectionRecipient3Title",
                          invalidInputsData,
                          "2",
                        )}
                      />
                      <EmailField
                        idProp="inspectionRecipient3Email"
                        label="Recipient's Email"
                        state={slide2}
                        setState={setSlide2}
                        val={slide2.inspectionRecipient3Email}
                        debouncedSave={slide2Mutation}
                        setIsLoading={setIsLoading}
                        autoData={predictiveTextFetched ? emails : []}
                        req={!isValidEmail(slide2.inspectionRecipient3Email)}
                        disabledInput={disabledInputCheck(
                          "inspectionRecipient3Email",
                          disabledInputs,
                        )}
                        disabledInputMessage={
                          disabledInputCheck(
                            "inspectionRecipient3Email",
                            disabledInputs,
                          ) === false
                            ? getInputMessage(
                                "inspectionRecipient3Email",
                                invalidInputsData,
                                "2",
                              )
                            : ""
                        }
                        invalidData={getInvalidData(
                          "inspectionRecipient3Email",
                          invalidInputsData,
                          "2",
                        )}
                      />
                    </>
                  ) : null}
                  {totalRecipients > 3 ? (
                    <>
                      <TextField
                        idProp="inspectionRecipient4"
                        label="Recipient's Name"
                        state={slide2}
                        setState={setSlide2}
                        val={slide2.inspectionRecipient4}
                        debouncedSave={slide2Mutation}
                        setIsLoading={setIsLoading}
                        autoData={predictiveTextFetched ? names : []}
                        req={!isValidName(slide2.inspectionRecipient4)}
                        type="name"
                        disabledInput={disabledInputCheck(
                          "inspectionRecipient4",
                          disabledInputs,
                        )}
                        disabledInputMessage={
                          disabledInputCheck(
                            "inspectionRecipient4",
                            disabledInputs,
                          ) === false
                            ? getInputMessage(
                                "inspectionRecipient4",
                                invalidInputsData,
                                "2",
                              )
                            : ""
                        }
                        invalidData={getInvalidData(
                          "inspectionRecipient4",
                          invalidInputsData,
                          "2",
                        )}
                      />
                      <TitleField
                        idProp="inspectionRecipient4Title"
                        label="Recipient's Title"
                        state={slide2}
                        setState={setSlide2}
                        val={slide2.inspectionRecipient4Title}
                        debouncedSave={slide2Mutation}
                        setIsLoading={setIsLoading}
                        autoData={predictiveTextFetched ? titles : []}
                        type="title"
                        disabledInput={disabledInputCheck(
                          "inspectionRecipient4Title",
                          disabledInputs,
                        )}
                        disabledInputMessage={
                          disabledInputCheck(
                            "inspectionRecipient4Title",
                            disabledInputs,
                          ) === false
                            ? getInputMessage(
                                "inspectionRecipient4Title",
                                invalidInputsData,
                                "2",
                              )
                            : ""
                        }
                        invalidData={getInvalidData(
                          "inspectionRecipient4Title",
                          invalidInputsData,
                          "2",
                        )}
                      />
                      <EmailField
                        idProp="inspectionRecipient4Email"
                        label="Recipient's Email"
                        state={slide2}
                        setState={setSlide2}
                        val={slide2.inspectionRecipient4Email}
                        debouncedSave={slide2Mutation}
                        setIsLoading={setIsLoading}
                        autoData={predictiveTextFetched ? emails : []}
                        req={!isValidEmail(slide2.inspectionRecipient4Email)}
                        disabledInput={disabledInputCheck(
                          "inspectionRecipient4Email",
                          disabledInputs,
                        )}
                        disabledInputMessage={
                          disabledInputCheck(
                            "inspectionRecipient4Email",
                            disabledInputs,
                          ) === false
                            ? getInputMessage(
                                "inspectionRecipient4Email",
                                invalidInputsData,
                                "2",
                              )
                            : ""
                        }
                        invalidData={getInvalidData(
                          "inspectionRecipient4Email",
                          invalidInputsData,
                          "2",
                        )}
                      />
                    </>
                  ) : null}
                  <div id="recipient-btns">
                    <Button
                      id="remove-recipient"
                      variant="contained"
                      onClick={removeRecipient}
                      disabled={
                        (requestStatus !== "IP" && requestStatus !== "I") ||
                        disableAll ||
                        totalRecipients === 0 ||
                        isLoading
                          ? true
                          : false
                      }
                    >
                      Remove
                    </Button>
                    <Button
                      id="add-recipient"
                      variant="contained"
                      onClick={addRecipient}
                      disabled={disableAddBtn === true ? true : false}
                    >
                      Add
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <TextField
                    idProp="inspectingCompany"
                    label="Name of Inspection Company"
                    state={slide2}
                    setState={setSlide2}
                    val={slide2.inspectingCompany}
                    debouncedSave={slide2Mutation}
                    setIsLoading={setIsLoading}
                    autoData={predictiveTextFetched ? companies : []}
                    req={
                      !slide2.inspectionRequest &&
                      slide2.inspectingCompany === ""
                    }
                    type="company"
                    disabledInput={disabledInputCheck(
                      "inspectingCompany",
                      disabledInputs,
                    )}
                    disabledInputMessage={
                      disabledInputCheck(
                        "inspectingCompany",
                        disabledInputs,
                      ) === false
                        ? getInputMessage(
                            "inspectingCompany",
                            invalidInputsData,
                            "2",
                          )
                        : ""
                    }
                    invalidData={getInvalidData(
                      "inspectingCompany",
                      invalidInputsData,
                      "2",
                    )}
                  />
                  <TextField
                    idProp="inspectorName"
                    label="Inspector Name"
                    state={slide2}
                    setState={setSlide2}
                    val={slide2.inspectorName}
                    debouncedSave={slide2Mutation}
                    setIsLoading={setIsLoading}
                    autoData={predictiveTextFetched ? names : []}
                    req={
                      !slide2.inspectionRequest &&
                      !isValidName(slide2.inspectorName, false)
                    }
                    type="name"
                    disabledInput={disabledInputCheck(
                      "inspectorName",
                      disabledInputs,
                    )}
                    disabledInputMessage={
                      disabledInputCheck("inspectorName", disabledInputs) ===
                      false
                        ? getInputMessage(
                            "inspectorName",
                            invalidInputsData,
                            "2",
                          )
                        : ""
                    }
                    invalidData={getInvalidData(
                      "inspectorName",
                      invalidInputsData,
                      "2",
                    )}
                  />
                  <PhoneField
                    idProp="inspectorPhone"
                    label="Inspector's Phone"
                    state={slide2}
                    setState={setSlide2}
                    val={slide2.inspectorPhone}
                    debouncedSave={slide2Mutation}
                    setIsLoading={setIsLoading}
                    autoData={predictiveTextFetched ? phoneNumbers : []}
                    req={
                      !slide2.inspectionRequest &&
                      !isValidPhoneNumber(slide2.inspectorPhone, false)
                    }
                    disabledInput={disabledInputCheck(
                      "inspectorPhone",
                      disabledInputs,
                    )}
                    disabledInputMessage={
                      disabledInputCheck("inspectorPhone", disabledInputs) ===
                      false
                        ? getInputMessage(
                            "inspectorPhone",
                            invalidInputsData,
                            "2",
                          )
                        : ""
                    }
                    invalidData={getInvalidData(
                      "inspectorPhone",
                      invalidInputsData,
                      "2",
                    )}
                  />
                  <EmailField
                    idProp="inspectorEmail"
                    label="Inspector's Email"
                    state={slide2}
                    setState={setSlide2}
                    val={slide2.inspectorEmail}
                    debouncedSave={slide2Mutation}
                    setIsLoading={setIsLoading}
                    autoData={predictiveTextFetched ? emails : []}
                    req={
                      !slide2.inspectionRequest &&
                      !isValidEmail(slide2.inspectorEmail, false)
                    }
                    disabledInput={disabledInputCheck(
                      "inspectorEmail",
                      disabledInputs,
                    )}
                    disabledInputMessage={
                      disabledInputCheck("inspectorEmail", disabledInputs) ===
                      false
                        ? getInputMessage(
                            "inspectorEmail",
                            invalidInputsData,
                            "2",
                          )
                        : ""
                    }
                    invalidData={getInvalidData(
                      "inspectorEmail",
                      invalidInputsData,
                      "2",
                    )}
                  />
                </>
              )}
              {+slide1.distAcr >= 1 ? (
                <>
                  <RadioGroupField
                    idProp="signRequest"
                    label="SWPPP Sign"
                    state={slide2}
                    setState={setSlide2}
                    val={slide2.signRequest}
                    debouncedSave={slide2Mutation}
                    setIsLoading={setIsLoading}
                    disabledInput={disabledInputCheck(
                      "signRequest",
                      disabledInputs,
                    )}
                  />
                  {slide2.signRequest ? (
                    <div id="swppp-signs">
                      <p id="sign-option-p">
                        Please select one of the following SWPPP sign options
                      </p>
                      <div className="inline-checkboxes">
                        <RadioField
                          idProp="sign3x3"
                          label={
                            slide1.onNativeLand === true
                              ? "4' x 4' Sign"
                              : "3' x 3' Sign"
                          }
                          state={slide2}
                          setState={setSlide2}
                          val={slide2.sign3x3}
                          req={
                            slide2.signRequest &&
                            !slide2.sign3x3 &&
                            !slide2.signBanner &&
                            !slide2.signStand
                          }
                          debouncedSave={slide2Mutation}
                          setIsLoading={setIsLoading}
                          disabledInput={disabledInputCheck(
                            "sign3x3",
                            disabledInputs,
                          )}
                        />
                        <RadioField
                          idProp="signStand"
                          label="Sign & Stand"
                          state={slide2}
                          setState={setSlide2}
                          val={slide2.signStand}
                          req={
                            slide2.signRequest &&
                            !slide2.signStand &&
                            !slide2.signBanner &&
                            !slide2.sign3x3
                          }
                          debouncedSave={slide2Mutation}
                          setIsLoading={setIsLoading}
                          disabledInput={disabledInputCheck(
                            "signStand",
                            disabledInputs,
                          )}
                        />
                        <RadioField
                          idProp="signBanner"
                          label="Sign Banner"
                          state={slide2}
                          setState={setSlide2}
                          val={slide2.signBanner}
                          req={
                            slide2.signRequest &&
                            !slide2.signBanner &&
                            !slide2.signStand &&
                            !slide2.sign3x3
                          }
                          debouncedSave={slide2Mutation}
                          setIsLoading={setIsLoading}
                          disabledInput={disabledInputCheck(
                            "signBanner",
                            disabledInputs,
                          )}
                        />
                      </div>
                    </div>
                  ) : null}
                </>
              ) : null}
            </>
          ) : null}
        </>
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};

export default SwpppServices;
