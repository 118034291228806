import CheckBox from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import SvgIcon from "@mui/material/SvgIcon";
import Tooltip from "@mui/material/Tooltip";
import React, { useContext, useEffect, useState } from "react";
import { InfoCircle } from "react-bootstrap-icons";
import "../../../../Tooltip/styles/tooltip.css";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";
import RadioField from "../Inputs/RadioField";
import TextField from "../Inputs/TextField.jsx";
import { FormContext } from "../context/FormProvider";
import { scrollToFirstRequiredField } from "../main-helper.js";
import { scopeOfWorkMessage } from "../messages/messages";
import "../styles/form-container.css";
import useSetSlide from "./Hooks/setSlide";
import useInvalidInputs from "./Hooks/useInvalidInputs";
import "./styles/project-type.css";

// Slide 4: Project Type/ Scope of Work
const ProjectType = ({ setSlideStatus, slideState, formStateLoaded }) => {
  const [openScopeTooltip, setOpenScopeTooltip] = useState(false);
  const [otherSow, setOtherSow] = useState("");
  const [disabledInputs, setDisabledInputs] = useState([]);
  const [availableSOW, setAvailableSOW] = useState([
    "Clear & Grub",
    "Plant Salvage",
    "Demolition",
    "Subgrade Preparation",
    "Aggregate Base Placement",
    "Milling",
    "Site Grading",
    "Roadway Excavation",
    "Excavation of Retention/Detention Basins",
    "Mass Grading",
    "Lot Grading",
    "Utility Infrastructure",
    "Utility Tie-Ins",
    "Bridge Construction",
    "Retaining Wall Construction",
    "Concrete Footings",
    "Concrete Flatwork",
    "Landscaping",
    "Paving",
    "Canal Excavation",
    "Canal Lining",
    "Seeding",
    "Vertical Construction",
    "Gabions/Rip Rap",
  ]);
  const [addedSOW, setAddedSOW] = useState([]);
  const {
    slide4,
    setSlide4,
    setIsLoading,
    requestStatus,
    populateInvalidInputs,
    disabledInputCheck,
    disableAll,
  } = useContext(FormContext);

  const { clientId, projectId, userId } = useContext(DashboardContext);

  useEffect(() => {
    scrollToFirstRequiredField(slide4);
  }, []);

  // Update Slide Logic
  const slide4Mutation = useSetSlide(
    clientId,
    projectId,
    4,
    setIsLoading,
    setSlideStatus,
  );

  // Get Invalid Inputs
  const invalidInputsData = useInvalidInputs(
    clientId,
    projectId,
    requestStatus,
  );

  useEffect(() => {
    if (formStateLoaded && slideState && slideState.scopeOfWork !== "") {
      let currentSOW = slideState.scopeOfWork.split(", ");
      setAddedSOW(() => currentSOW);
      // Create a unique array of currentSOWs and availableSOWs
      let availableSet = Array.from(new Set([...availableSOW, ...currentSOW]));
      // Set the availableSOW state to the unique array
      setAvailableSOW(() => [...availableSet]);
    }
  }, [formStateLoaded]);

  // Event handler for text changes in the SOW input field
  const handleSOWCheck = (e) => {
    // get the value of the checkbox
    let sowValue = e.target.value;
    let newProjectSOW = [];
    // if the value is checked and not in addedSOW, add it to addedSOW
    if (e.target.checked && !addedSOW.includes(sowValue)) {
      newProjectSOW = [...addedSOW, sowValue];
      setAddedSOW(newProjectSOW);
      // if the value is unchecked and in addedSOW, remove it from addedSOW
    } else if (!e.target.checked && addedSOW.includes(sowValue)) {
      newProjectSOW = addedSOW.filter((sow) => sow !== sowValue);
      setAddedSOW(newProjectSOW);
    }
    // mutate the slide4 state by joining the addedSOW array into a string separated by commas
    let sowString = newProjectSOW.join(", ");
    let newSlide4 = { ...slide4, scopeOfWork: sowString };
    setSlide4(newSlide4);

    setIsLoading(true);
    slide4Mutation.mutate(newSlide4);
  };

  // Populate invalid inputs
  useEffect(() => {
    populateInvalidInputs(
      invalidInputsData,
      disabledInputs,
      setDisabledInputs,
      "4",
    );
  }, [invalidInputsData.data, invalidInputsData.isFetched]);

  return (
    <>
      {formStateLoaded ? (
        <>
          <div className={"project-type-container"}>
            <RadioField
              idProp="t1"
              label="ADOT"
              state={slide4}
              setState={setSlide4}
              val={slide4.t1}
              debouncedSave={slide4Mutation}
              setIsLoading={setIsLoading}
              disabledInput={disabledInputCheck("t1", disabledInputs)}
            />
            <RadioField
              idProp="t8"
              label="Recreational Area"
              state={slide4}
              setState={setSlide4}
              val={slide4.t8}
              debouncedSave={slide4Mutation}
              setIsLoading={setIsLoading}
              disabledInput={disabledInputCheck("t8", disabledInputs)}
            />
            <RadioField
              idProp="t2"
              label="Airport"
              state={slide4}
              setState={setSlide4}
              val={slide4.t2}
              debouncedSave={slide4Mutation}
              setIsLoading={setIsLoading}
              disabledInput={disabledInputCheck("t2", disabledInputs)}
            />
            <RadioField
              idProp="t9"
              label="Residence"
              state={slide4}
              setState={setSlide4}
              val={slide4.t9}
              debouncedSave={slide4Mutation}
              setIsLoading={setIsLoading}
              disabledInput={disabledInputCheck("t9", disabledInputs)}
            />
            <RadioField
              idProp="t3"
              label="Commercial"
              state={slide4}
              setState={setSlide4}
              val={slide4.t3}
              debouncedSave={slide4Mutation}
              setIsLoading={setIsLoading}
              disabledInput={disabledInputCheck("t3", disabledInputs)}
            />
            <RadioField
              idProp="t10"
              label="Roads"
              state={slide4}
              setState={setSlide4}
              val={slide4.t10}
              debouncedSave={slide4Mutation}
              setIsLoading={setIsLoading}
              disabledInput={disabledInputCheck("t10", disabledInputs)}
            />
            <RadioField
              idProp="t4"
              label="Flood Control"
              state={slide4}
              setState={setSlide4}
              val={slide4.t4}
              debouncedSave={slide4Mutation}
              setIsLoading={setIsLoading}
              disabledInput={disabledInputCheck("t4", disabledInputs)}
            />
            <RadioField
              idProp="t11"
              label="Schools"
              state={slide4}
              setState={setSlide4}
              val={slide4.t11}
              debouncedSave={slide4Mutation}
              setIsLoading={setIsLoading}
              disabledInput={disabledInputCheck("t11", disabledInputs)}
            />
            <RadioField
              idProp="t5"
              label="Mining Sand Gravel"
              state={slide4}
              setState={setSlide4}
              val={slide4.t5}
              debouncedSave={slide4Mutation}
              setIsLoading={setIsLoading}
              disabledInput={disabledInputCheck("t5", disabledInputs)}
            />
            <RadioField
              idProp="t12"
              label="Subdivision"
              state={slide4}
              setState={setSlide4}
              val={slide4.t12}
              debouncedSave={slide4Mutation}
              setIsLoading={setIsLoading}
              disabledInput={disabledInputCheck("t12", disabledInputs)}
            />
            <RadioField
              idProp="t6"
              label="Municipal/Public"
              state={slide4}
              setState={setSlide4}
              val={slide4.t6}
              debouncedSave={slide4Mutation}
              setIsLoading={setIsLoading}
              disabledInput={disabledInputCheck("t6", disabledInputs)}
            />
            <RadioField
              idProp="t13"
              label="Utilities"
              state={slide4}
              setState={setSlide4}
              val={slide4.t13}
              debouncedSave={slide4Mutation}
              setIsLoading={setIsLoading}
              disabledInput={disabledInputCheck("t13", disabledInputs)}
            />
            <RadioField
              idProp="t7"
              label="Pipeline"
              state={slide4}
              setState={setSlide4}
              val={slide4.t7}
              debouncedSave={slide4Mutation}
              setIsLoading={setIsLoading}
              disabledInput={disabledInputCheck("t7", disabledInputs)}
            />
            <RadioField
              idProp="other"
              label="Other"
              state={slide4}
              setState={setSlide4}
              val={slide4.other}
              debouncedSave={slide4Mutation}
              setIsLoading={setIsLoading}
              disabledInput={disabledInputCheck("other", disabledInputs)}
            />
            {slide4.other === true ? (
              <TextField
                idProp="otherText"
                label="Type of Project"
                state={slide4}
                setState={setSlide4}
                val={slide4.otherText}
                req={slide4.other === true && slide4.otherText === ""}
                debouncedSave={slide4Mutation}
                setIsLoading={setIsLoading}
                disabledInput={disabledInputCheck("otherText", disabledInputs)}
              />
            ) : null}
          </div>
          <div className="sow-container">
            <hr className="sow-hr" />
            <div id="sow-heading-container">
              <h1 id="sow-heading">Scope of Work</h1>
              <Tooltip
                id="sow-tooltip"
                title={scopeOfWorkMessage}
                placement="right"
                classes={{
                  tooltip: "sow-tooltip",
                  popper: "sow-popper",
                  arrow: "sow-arrow",
                }}
                arrow
              >
                <InfoCircle
                  className="sow-info-circ"
                  id="sow-info-circ"
                  color="white"
                  size={20}
                  onMouseEnter={() => setOpenScopeTooltip(true)}
                  onMouseLeave={() => setOpenScopeTooltip(false)}
                />
              </Tooltip>
            </div>
            <hr className="sow-hr" />
            <div className="sow-checkboxes">
              {availableSOW.map((sow, index) => {
                return (
                  <FormControlLabel
                    key={`${sow}${index}`}
                    className="sow-form-label"
                    id={`sow-checkbox-label`}
                    label={sow}
                    labelPlacement="end"
                    value={sow}
                    checked={addedSOW.includes(sow)}
                    onChange={handleSOWCheck}
                    disabled={
                      (requestStatus !== "I" && requestStatus !== "IP") ||
                      disableAll
                    }
                    classes={{
                      label: "sow-form-control-label",
                      root: "sow-form-control-root",
                    }}
                    sx={{
                      cursor:
                        (requestStatus !== "I" && requestStatus !== "IP") ||
                        disableAll
                          ? "not-allowed !important"
                          : "pointer",
                    }}
                    control={
                      <Checkbox
                        className="sow-checkbox"
                        id={`sow`}
                        value={sow}
                        classes={{
                          root: "sow-checkbox-root",
                          checked: "sow-checkbox-checked",
                        }}
                        icon={
                          <SvgIcon
                            className="sow-unchecked-icon"
                            viewBox="0 0 24 24"
                            component={CheckBoxOutlineBlankIcon}
                          />
                        }
                        checkedIcon={
                          <SvgIcon
                            className="sow-checked-icon"
                            viewBox="3 3 18 18"
                            component={CheckBox}
                          />
                        }
                      >
                        {sow}
                      </Checkbox>
                    }
                  />
                );
              })}
              <div id="add-sow-container">
                <FormControl
                  variant="outlined"
                  id="new-sow-form-control"
                  classes={{
                    root: "sow-text-form-control-root",
                  }}
                >
                  <InputLabel
                    id="new-sow-label"
                    htmlFor="newSOW-field"
                    classes={{
                      shrink: "sow-label-shrink",
                    }}
                  >
                    Add Scope of Work
                  </InputLabel>
                  <OutlinedInput
                    type="text"
                    id="newSOW-field"
                    label="Other Scope of Work"
                    value={otherSow}
                    onChange={(e) => {
                      setOtherSow(() => e.target.value);
                    }}
                    classes={{
                      root: "sow-field-root",
                      input: "sow-field-input",
                      notchedOutline: "sow-notchedOutline",
                    }}
                    disabled={requestStatus !== "IP" && requestStatus !== "I"}
                  />
                </FormControl>
                <Button
                  className="add-sow-btn"
                  id="add-sow-btn"
                  onClick={() => {
                    if (otherSow !== "" && !availableSOW.includes(otherSow)) {
                      setAvailableSOW((prevState) => [...prevState, otherSow]);
                      setOtherSow(() => "");
                    }
                  }}
                  variant="contained"
                  disabled={
                    (requestStatus !== "IP" && requestStatus !== "I") ||
                    disableAll
                  }
                >
                  Add
                </Button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};

export default ProjectType;
