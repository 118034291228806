import React, { useState } from "react";
import "./styles/acreage-calulator.css";

// Square footage to acreage calculator
const AcreageCalculator = ({ idProp, state, setState }) => {
  const [value, setValue] = useState("");
  const [acreage, setAcreage] = useState(0);
  const handleCalculate = (value) => {
    return (+value / 43560).toFixed(2);
  };

  // Handle value change
  const handleValueChange = (e) => {
    // Remove any non-number characters but keep the decimal point and comma
    let formattedValue = e.target.value.replace(/[^0-9.,]/g, "");
    setValue(formattedValue);
    let calculated = handleCalculate(+formattedValue);
    setAcreage(calculated);
    setState({
      ...state,
      [idProp]: calculated.toString(),
    });
  };
  return (
    <>
      <div className="calculator-container">
        <div className="calc-container">
          <p id="unit-label">Acreage Calculator:</p>
          <input
            id="input-value"
            type="number"
            min="1"
            value={value}
            onChange={handleValueChange}
          />
          <p id="unit-label">ft²</p>
          <p className="acreage-value">{` ≈ `}</p>
          <p className="acreage-value">{` ${acreage} acres`}</p>
        </div>
      </div>
    </>
  );
};

export default AcreageCalculator;
