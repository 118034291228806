import React, { useContext, useEffect, useState } from "react";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";
import {
  isValidEmail,
  isValidName,
  isValidPhoneNumber,
} from "../Helper/formbtnhelper";
import EmailField from "../Inputs/EmailField";
import PhoneField from "../Inputs/PhoneField";
import RadioGroupField from "../Inputs/RadioGroupField";
import TextField from "../Inputs/TextField";
import TitleField from "../Inputs/TitleField";
import { FormContext } from "../context/FormProvider";
import { scrollToFirstRequiredField } from "../main-helper";
import useSetSlide from "./Hooks/setSlide";
import useInvalidInputs from "./Hooks/useInvalidInputs";
import "./styles/dust-services.css";

// Slide 3: Dust Services
const DustServices = ({ setSlideStatus, slideState, formStateLoaded }) => {
  const [disabledInputs, setDisabledInputs] = useState([]);

  const {
    slide1,
    slide3,
    setSlide3,
    setIsLoading,
    requestStatus,
    names,
    phoneNumbers,
    emails,
    titles,
    predictiveTextFetched,
    populateInvalidInputs,
    getInvalidData,
    getInputMessage,
    disabledInputCheck,
  } = useContext(FormContext);

  const { clientId, projectId } = useContext(DashboardContext);

  // Scroll to required field or top
  useEffect(() => {
    scrollToFirstRequiredField(slide3);
  }, []);

  // Update Slide Logic
  const slide3Mutation = useSetSlide(
    clientId,
    projectId,
    3,
    setIsLoading,
    setSlideStatus,
  );

  // Invalid Inputs Logic
  const invalidInputsData = useInvalidInputs(
    clientId,
    projectId,
    requestStatus,
  );

  // Populate Invalid Inputs
  useEffect(() => {
    populateInvalidInputs(
      invalidInputsData,
      disabledInputs,
      setDisabledInputs,
      "3",
    );
  }, [invalidInputsData.data, invalidInputsData.isFetched]);

  return (
    <>
      {formStateLoaded ? (
        <>
          <RadioGroupField
            idProp="dustControlRequest"
            label="Dust Control Services?"
            state={slide3}
            setState={setSlide3}
            val={slide3.dustControlRequest}
            debouncedSave={slide3Mutation}
            setIsLoading={setIsLoading}
            disabledInput={disabledInputCheck(
              "dustControlRequest",
              disabledInputs,
            )}
          />
          {slide3.dustControlRequest && (
            <>
              <RadioGroupField
                idProp="manualLogsRequest"
                label="Dust Control Manual & Logs?"
                state={slide3}
                setState={setSlide3}
                val={slide3.manualLogsRequest}
                debouncedSave={slide3Mutation}
                setIsLoading={setIsLoading}
                disabledInput={disabledInputCheck(
                  "manualLogsRequest",
                  disabledInputs,
                )}
              />
              {slide3.manualLogsRequest && (
                <>
                  <p className="dust-contact-info">
                    {
                      "Please provide the contact information for the Dust Control Coordinator"
                    }
                  </p>
                  <TextField
                    idProp="dcc"
                    label="Dust Control Coordinator Name"
                    state={slide3}
                    setState={setSlide3}
                    val={slide3.dcc}
                    debouncedSave={slide3Mutation}
                    setIsLoading={setIsLoading}
                    autoData={predictiveTextFetched ? names : []}
                    req={
                      slide3.manualLogsRequest &&
                      !isValidName(slide3.dcc, false)
                    }
                    type="name"
                    disabledInput={disabledInputCheck("dcc", disabledInputs)}
                    disabledInputMessage={
                      disabledInputCheck("dcc", disabledInputs) === false
                        ? getInputMessage("dcc", invalidInputsData, "3")
                        : ""
                    }
                    invalidData={getInvalidData("dcc", invalidInputsData, "3")}
                  />
                  <TitleField
                    idProp="dccTitle"
                    label="Coordinator's Title"
                    state={slide3}
                    setState={setSlide3}
                    val={slide3.dccTitle}
                    debouncedSave={slide3Mutation}
                    setIsLoading={setIsLoading}
                    autoData={predictiveTextFetched ? titles : []}
                    req={slide3.manualLogsRequest && slide3.dccTitle === ""}
                    type="title"
                    disabledInput={
                      disabledInputCheck("dccTitle", disabledInputs) === true
                        ? true
                        : false
                    }
                    disabledInputMessage={
                      disabledInputCheck("dccTitle", disabledInputs) === false
                        ? getInputMessage("dccTitle", invalidInputsData, "3")
                        : ""
                    }
                    invalidData={getInvalidData(
                      "dccTitle",
                      invalidInputsData,
                      "3",
                    )}
                  />
                  <PhoneField
                    idProp="dccPhone"
                    label="Coordinator's Phone"
                    state={slide3}
                    setState={setSlide3}
                    val={slide3.dccPhone}
                    debouncedSave={slide3Mutation}
                    setIsLoading={setIsLoading}
                    autoData={predictiveTextFetched ? phoneNumbers : []}
                    req={
                      slide3.manualLogsRequest &&
                      !isValidPhoneNumber(slide3.dccPhone, false)
                    }
                    disabledInput={
                      disabledInputCheck("dccPhone", disabledInputs) === true
                        ? true
                        : false
                    }
                    disabledInputMessage={
                      disabledInputCheck("dccPhone", disabledInputs) === false
                        ? getInputMessage("dccPhone", invalidInputsData, "3")
                        : ""
                    }
                    invalidData={getInvalidData(
                      "dccPhone",
                      invalidInputsData,
                      "3",
                    )}
                  />
                  <EmailField
                    idProp="dccEmail"
                    label="Coordinator's Email"
                    state={slide3}
                    setState={setSlide3}
                    val={slide3.dccEmail}
                    debouncedSave={slide3Mutation}
                    setIsLoading={setIsLoading}
                    autoData={predictiveTextFetched ? emails : []}
                    req={
                      slide3.manualLogsRequest &&
                      !isValidEmail(slide3.dccEmail, false)
                    }
                    disabledInput={
                      disabledInputCheck("dccEmail", disabledInputs) === true
                        ? true
                        : false
                    }
                    disabledInputMessage={
                      disabledInputCheck("dccEmail", disabledInputs) === false
                        ? getInputMessage("dccEmail", invalidInputsData, "3")
                        : ""
                    }
                    invalidData={getInvalidData(
                      "dccEmail",
                      invalidInputsData,
                      "3",
                    )}
                  />
                </>
              )}
              <RadioGroupField
                idProp="dustAuditRequest"
                label="Dust Control Audits (Site/Logs)?"
                state={slide3}
                setState={setSlide3}
                val={slide3.dustAuditRequest}
                debouncedSave={slide3Mutation}
                setIsLoading={setIsLoading}
                disabledInput={disabledInputCheck(
                  "dustAuditRequest",
                  disabledInputs,
                )}
              />
              {slide3.dustAuditRequest && (
                <>
                  <p className="dust-contact-info">
                    {
                      "Please provide the contact information for the individual who will need to receive the Dust Audit Reports"
                    }
                  </p>
                  <TextField
                    idProp="dustAuditContact"
                    label="Dust Audit Recipient"
                    state={slide3}
                    setState={setSlide3}
                    val={slide3.dustAuditContact}
                    debouncedSave={slide3Mutation}
                    setIsLoading={setIsLoading}
                    autoData={predictiveTextFetched ? names : []}
                    req={
                      slide3.dustAuditRequest &&
                      !isValidName(slide3.dustAuditContact, false)
                    }
                    type="name"
                    disabledInput={disabledInputCheck(
                      "dustAuditContact",
                      disabledInputs,
                    )}
                    disabledInputMessage={
                      disabledInputCheck("dustAuditContact", disabledInputs) ===
                      false
                        ? getInputMessage(
                            "dustAuditContact",
                            invalidInputsData,
                            "3",
                          )
                        : ""
                    }
                    invalidData={getInvalidData(
                      "dustAuditContact",
                      invalidInputsData,
                      "3",
                    )}
                  />
                  <EmailField
                    idProp="dustAuditContactEmail"
                    label="Dust Audit Recipient Email"
                    state={slide3}
                    setState={setSlide3}
                    val={slide3.dustAuditContactEmail}
                    debouncedSave={slide3Mutation}
                    setIsLoading={setIsLoading}
                    autoData={predictiveTextFetched ? emails : []}
                    req={
                      slide3.dustAuditRequest &&
                      !isValidEmail(slide3.dustAuditContactEmail, false)
                    }
                    disabledInput={
                      disabledInputCheck(
                        "dustAuditContactEmail",
                        disabledInputs,
                      ) === true
                        ? true
                        : false
                    }
                    disabledInputMessage={
                      disabledInputCheck(
                        "dustAuditContactEmail",
                        disabledInputs,
                      ) === false
                        ? getInputMessage(
                            "dustAuditContactEmail",
                            invalidInputsData,
                            "3",
                          )
                        : ""
                    }
                    invalidData={getInvalidData(
                      "dustAuditContactEmail",
                      invalidInputsData,
                      "3",
                    )}
                  />
                </>
              )}
              {+slide1.distAcr >= 5 && (
                <>
                  <RadioGroupField
                    idProp="dustSignRequest"
                    label="Dust Control Sign?"
                    state={slide3}
                    setState={setSlide3}
                    val={slide3.dustSignRequest}
                    debouncedSave={slide3Mutation}
                    setIsLoading={setIsLoading}
                    disabledInput={disabledInputCheck(
                      "dustSignRequest",
                      disabledInputs,
                    )}
                  />
                  {slide3.dustSignRequest && (
                    <>
                      <p className="dust-contact-info">
                        {
                          "Please provide the contact information for the individual for the Dust Sign"
                        }
                      </p>
                      <TextField
                        idProp="dcName"
                        label="Contact for Dust Sign"
                        state={slide3}
                        setState={setSlide3}
                        val={slide3.dcName}
                        debouncedSave={slide3Mutation}
                        setIsLoading={setIsLoading}
                        autoData={predictiveTextFetched ? names : []}
                        req={
                          slide3.dustSignRequest &&
                          !isValidName(slide3.dcName, false)
                        }
                        type="name"
                        disabledInput={disabledInputCheck(
                          "dcName",
                          disabledInputs,
                        )}
                        disabledInputMessage={
                          disabledInputCheck("dcName", disabledInputs) === false
                            ? getInputMessage("dcName", invalidInputsData, "3")
                            : ""
                        }
                        invalidData={getInvalidData(
                          "dcName",
                          invalidInputsData,
                          "3",
                        )}
                      />
                      <PhoneField
                        idProp="dcPhone"
                        label="Contact Phone"
                        state={slide3}
                        setState={setSlide3}
                        val={slide3.dcPhone}
                        debouncedSave={slide3Mutation}
                        setIsLoading={setIsLoading}
                        autoData={predictiveTextFetched ? phoneNumbers : []}
                        req={
                          slide3.dustSignRequest &&
                          !isValidPhoneNumber(slide3.dcPhone, false)
                        }
                        disabledInput={
                          disabledInputCheck("dcPhone", disabledInputs) === true
                            ? true
                            : false
                        }
                        disabledInputMessage={
                          disabledInputCheck("dcPhone", disabledInputs) ===
                          false
                            ? getInputMessage("dcPhone", invalidInputsData, "3")
                            : ""
                        }
                        invalidData={getInvalidData(
                          "dcPhone",
                          invalidInputsData,
                          "3",
                        )}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};

export default DustServices;
