import React, { useContext, useEffect, useState } from "react";
// import AttachCheckboxes from "../Inputs/AttachCheckboxes.jsx";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";
import AttachFileField from "../Inputs/AttachFileField.jsx";
import FileField from "../Inputs/FileField";
import TextField from "../Inputs/TextField.jsx";
import { FormContext } from "../context/FormProvider";
import { scrollToFirstRequiredField } from "../main-helper.js";
import useSetSlide from "./Hooks/setSlide";
import useInvalidInputs from "./Hooks/useInvalidInputs";
import "./styles/fileslide.css";

// Slide 9: FileSlide
const FileSlide = ({ setSlideStatus, slideState, formStateLoaded }) => {
  const [disabledInputs, setDisabledInputs] = useState([]);
  const {
    slide1,
    slide2,
    slide9,
    setSlide9,
    setIsLoading,
    requestStatus,
    populateInvalidInputs,
    getInvalidData,
    getInputMessage,
    disabledInputCheck,
  } = useContext(FormContext);

  const { clientId, projectId } = useContext(DashboardContext);

  const [isUploading, setIsUploading] = useState(false);

  // On Mount, the page should scroll to the top
  useEffect(() => {
    scrollToFirstRequiredField(slide9);
  }, []);

  const slide9Mutation = useSetSlide(
    clientId,
    projectId,
    9,
    setIsLoading,
    setSlideStatus,
  );

  // INVALID INPUTS LOGIC
  const invalidInputsData = useInvalidInputs(
    clientId,
    projectId,
    requestStatus,
  );

  // Populate invalid inputs
  useEffect(() => {
    populateInvalidInputs(
      invalidInputsData,
      disabledInputs,
      setDisabledInputs,
      "9",
    );
  }, [invalidInputsData.data, invalidInputsData.isFetched]);

  return (
    <>
      {formStateLoaded ? (
        <>
          <AttachFileField
            idProp="dustPermit"
            label="Dust Permit"
            state={slide9}
            setState={setSlide9}
            val={slide9.dustPermit.fileStatus}
            debouncedSave={slide9Mutation}
            disabledInput={disabledInputCheck("dustPermit", disabledInputs)}
            required={
              formStateLoaded && slideState
                ? slideState["dustPermit"].required
                : slideState["dustPermit"].required
            }
          />
          {slide9.dustPermit.fileStatus === "Attached" ? (
            <>
              <TextField
                idProp="dustPermitNumber"
                label="Dust Permit Number"
                state={slide9}
                setState={setSlide9}
                val={slide9.dustPermitNumber}
                debouncedSave={slide9Mutation}
                setIsLoading={setIsLoading}
                disabledInput={disabledInputCheck(
                  "dustPermitNumber",
                  disabledInputs,
                )}
                disabledInputMessage={
                  disabledInputCheck("dustPermitNumber", disabledInputs) ===
                  false
                    ? getInputMessage(
                        "dustPermitNumber",
                        invalidInputsData,
                        "9",
                      )
                    : ""
                }
                invalidData={getInvalidData(
                  "dustPermitNumber",
                  invalidInputsData,
                  "9",
                )}
              />
              <FileField
                label="Upload Dust Permit"
                idProp="dustPermit"
                val={slide9.dustPermit.file}
                state={slide9}
                setState={setSlide9}
                isUploading={isUploading}
                setIsUploading={setIsUploading}
              />
            </>
          ) : null}
          {/* Inspector Qualifications */}
          {!slide2.inspectionRequest ? (
            <>
              <AttachFileField
                idProp="inspectorQualifications"
                label="SWPPP Inspector Qualifications"
                state={slide9}
                setState={setSlide9}
                val={slide9.inspectorQualifications.fileStatus}
                debouncedSave={slide9Mutation}
                disabledInput={disabledInputCheck(
                  "inspectorQualifications",
                  disabledInputs,
                )}
                required={
                  formStateLoaded && slideState
                    ? slideState["inspectorQualifications"].required
                    : slideState["inspectorQualifications"].required
                }
              />
              {slide9.inspectorQualifications.fileStatus === "Attached" ? (
                <>
                  <FileField
                    label="Upload Qualifications"
                    idProp="inspectorQualifications"
                    val={slide9.inspectorQualifications.file}
                    state={slide9}
                    setState={setSlide9}
                    isUploading={isUploading}
                    setIsUploading={setIsUploading}
                  />
                </>
              ) : null}
            </>
          ) : null}
          {/* SWMP/SWPPP SIte Plans */}
          <AttachFileField
            idProp="swmpPlan"
            label="Existing Stormwater Management Plan or SWPPP Site Plan?"
            state={slide9}
            setState={setSlide9}
            val={slide9.swmpPlan.fileStatus}
            debouncedSave={slide9Mutation}
            disabledInput={disabledInputCheck("swmpPlan", disabledInputs)}
            required={
              formStateLoaded && slideState
                ? slideState["swmpPlan"].required
                : slideState["swmpPlan"].required
            }
          />
          {slide9.swmpPlan.fileStatus === "Attached" ? (
            <>
              <FileField
                label="Upload SWMP"
                idProp="swmpPlan"
                val={slide9.swmpPlan}
                state={slide9}
                setState={setSlide9}
                isUploading={isUploading}
                setIsUploading={setIsUploading}
              />
            </>
          ) : null}
          {+slide1.totAcr > 1 ? (
            <>
              {/* Landscape Plans */}
              <AttachFileField
                idProp="landscapePlan"
                label="Landscape Plans"
                state={slide9}
                setState={setSlide9}
                val={slide9.landscapePlan.fileStatus}
                debouncedSave={slide9Mutation}
                disabledInput={disabledInputCheck(
                  "landscapePlan",
                  disabledInputs,
                )}
                required={
                  formStateLoaded && slideState
                    ? slideState["landscapePlan"].required
                    : slideState["landscapePlan"].required
                }
              />
              {slide9.landscapePlan.fileStatus === "Attached" ? (
                <>
                  <FileField
                    label="Upload Landscape Plan"
                    idProp="landscapePlan"
                    val={slide9.landscapePlan}
                    state={slide9}
                    setState={setSlide9}
                    isUploading={isUploading}
                    setIsUploading={setIsUploading}
                  />
                </>
              ) : null}
            </>
          ) : null}
          {/* Grading Plans */}
          <AttachFileField
            idProp="gradingPlan"
            label="Grading Plans & Drainage Plans"
            state={slide9}
            setState={setSlide9}
            val={slide9.gradingPlan.fileStatus}
            debouncedSave={slide9Mutation}
            disabledInput={disabledInputCheck("gradingPlan", disabledInputs)}
            required={
              formStateLoaded && slideState
                ? slideState["gradingPlan"].required
                : slideState["gradingPlan"].required
            }
          />
          {slide9.gradingPlan.fileStatus === "Attached" ? (
            <>
              <FileField
                label="Upload Grading Plan"
                idProp="gradingPlan"
                val={slide9.gradingPlan.file}
                state={slide9}
                setState={setSlide9}
                isUploading={isUploading}
                setIsUploading={setIsUploading}
              />
            </>
          ) : null}
          {+slide1.totAcr > 1 ? (
            <>
              {/* Drainage Report */}
              <AttachFileField
                idProp="drainageReport"
                label="Drainage Report"
                state={slide9}
                setState={setSlide9}
                val={slide9.drainageReport.fileStatus}
                debouncedSave={slide9Mutation}
                disabledInput={disabledInputCheck(
                  "drainageReport",
                  disabledInputs,
                )}
                required={
                  formStateLoaded && slideState
                    ? slideState["drainageReport"].required
                    : slideState["drainageReport"].required
                }
              />
              {slide9.drainageReport.fileStatus === "Attached" ? (
                <>
                  <FileField
                    label="Upload Drainage Report"
                    idProp="drainageReport"
                    val={slide9.drainageReport.file}
                    state={slide9}
                    setState={setSlide9}
                    isUploading={isUploading}
                    setIsUploading={setIsUploading}
                  />
                </>
              ) : null}
            </>
          ) : null}

          {+slide1.totAcr > 1 ? (
            <>
              {/* Soils Report */}
              <AttachFileField
                idProp="soilsReport"
                label="Geotechnical/Soils Report"
                state={slide9}
                setState={setSlide9}
                val={slide9.soilsReport.fileStatus}
                debouncedSave={slide9Mutation}
                disabledInput={disabledInputCheck(
                  "soilsReport",
                  disabledInputs,
                )}
                required={
                  formStateLoaded && slideState
                    ? slideState["soilsReport"].required
                    : slideState["soilsReport"].required
                }
              />
              {slide9.soilsReport.fileStatus === "Attached" ? (
                <>
                  <FileField
                    label="Upload Soils Report"
                    idProp="soilsReport"
                    val={slide9.soilsReport.file}
                    state={slide9}
                    setState={setSlide9}
                    isUploading={isUploading}
                    setIsUploading={setIsUploading}
                  />
                </>
              ) : null}
              {/* Environmental Report */}
              <AttachFileField
                idProp="environmentalReport"
                label="Phase I or II Environmental Report"
                state={slide9}
                setState={setSlide9}
                val={slide9.environmentalReport.fileStatus}
                debouncedSave={slide9Mutation}
                disabledInput={disabledInputCheck(
                  "environmentalReport",
                  disabledInputs,
                )}
                required={
                  formStateLoaded && slideState
                    ? slideState["environmentalReport"].required
                    : slideState["environmentalReport"].required
                }
              />
              {slide9.environmentalReport.fileStatus === "Attached" ? (
                <>
                  <FileField
                    label="Upload Environmental Report"
                    idProp="environmentalReport"
                    val={slide9.environmentalReport.file}
                    state={slide9}
                    setState={setSlide9}
                    isUploading={isUploading}
                    setIsUploading={setIsUploading}
                  />
                </>
              ) : null}
              {/* Endangered Species Report */}
              <AttachFileField
                idProp="endangeredSpeciesReport"
                label="Biological or Endangered Species Report"
                state={slide9}
                setState={setSlide9}
                val={slide9.endangeredSpeciesReport.fileStatus}
                debouncedSave={slide9Mutation}
                disabledInput={disabledInputCheck(
                  "endangeredSpeciesReport",
                  disabledInputs,
                )}
                required={
                  formStateLoaded && slideState
                    ? slideState["endangeredSpeciesReport"].required
                    : slideState["endangeredSpeciesReport"].required
                }
              />
              {slide9.endangeredSpeciesReport.fileStatus === "Attached" ? (
                <>
                  <FileField
                    label="Upload Endangered Species Report"
                    idProp="endangeredSpeciesReport"
                    val={slide9.endangeredSpeciesReport.file}
                    state={slide9}
                    setState={setSlide9}
                    isUploading={isUploading}
                    setIsUploading={setIsUploading}
                  />
                </>
              ) : null}
              {/* Archeological Report */}
              <AttachFileField
                idProp="archeologicalReport"
                label="Archeological Report"
                state={slide9}
                setState={setSlide9}
                val={slide9.archeologicalReport.fileStatus}
                debouncedSave={slide9Mutation}
                disabledInput={disabledInputCheck(
                  "archeologicalReport",
                  disabledInputs,
                )}
                required={
                  formStateLoaded && slideState
                    ? slideState["archeologicalReport"].required
                    : slideState["archeologicalReport"].required
                }
              />
              {slide9.archeologicalReport.fileStatus === "Attached" ? (
                <>
                  <FileField
                    label="Upload Archeological Report"
                    idProp="archeologicalReport"
                    val={slide9.archeologicalReport.file}
                    state={slide9}
                    setState={setSlide9}
                    isUploading={isUploading}
                    setIsUploading={setIsUploading}
                  />
                </>
              ) : null}
              {/* Corp Of Engineers Report */}
              <AttachFileField
                idProp="corpOfEngineersReport"
                label="Corp Of Engineers 404 Report and Permit"
                state={slide9}
                setState={setSlide9}
                val={slide9.corpOfEngineersReport.fileStatus}
                debouncedSave={slide9Mutation}
                disabledInput={disabledInputCheck(
                  "corpOfEngineersReport",
                  disabledInputs,
                )}
                required={
                  formStateLoaded && slideState
                    ? slideState["corpOfEngineersReport"].required
                    : slideState["corpOfEngineersReport"].required
                }
              />
              {slide9.corpOfEngineersReport.fileStatus === "Attached" ? (
                <>
                  <FileField
                    label="Upload Corp Of Engineers Report"
                    idProp="corpOfEngineersReport"
                    val={slide9.corpOfEngineersReport.file}
                    state={slide9}
                    setState={setSlide9}
                    isUploading={isUploading}
                    setIsUploading={setIsUploading}
                  />
                </>
              ) : null}
            </>
          ) : null}
          {/* Site Plan */}
          {/* ConstructionSchedule */}
          <AttachFileField
            idProp="constructionSchedule"
            label="Construction Schedule"
            state={slide9}
            setState={setSlide9}
            val={slide9.constructionSchedule.fileStatus}
            debouncedSave={slide9Mutation}
            disabledInput={disabledInputCheck(
              "constructionSchedule",
              disabledInputs,
            )}
            required={
              formStateLoaded && slideState
                ? slideState["constructionSchedule"].required
                : slideState["constructionSchedule"].required
            }
          />
          {slide9.constructionSchedule.fileStatus === "Attached" ? (
            <>
              <FileField
                label="Upload Construction Schedule"
                idProp="constructionSchedule"
                val={slide9.constructionSchedule.file}
                state={slide9}
                setState={setSlide9}
                isUploading={isUploading}
                setIsUploading={setIsUploading}
              />
            </>
          ) : null}
          {/* Project Specifications */}
          <AttachFileField
            idProp="projectSpecifications"
            label="Project Specifications"
            state={slide9}
            setState={setSlide9}
            val={slide9.projectSpecifications.fileStatus}
            debouncedSave={slide9Mutation}
            disabledInput={disabledInputCheck(
              "projectSpecifications",
              disabledInputs,
            )}
            required={
              formStateLoaded && slideState
                ? slideState["projectSpecifications"].required
                : slideState["projectSpecifications"].required
            }
          />
          {slide9.projectSpecifications.fileStatus === "Attached" ? (
            <>
              <FileField
                label="Upload Project Specifications"
                idProp="projectSpecifications"
                val={slide9.projectSpecifications.file}
                state={slide9}
                setState={setSlide9}
                isUploading={isUploading}
                setIsUploading={setIsUploading}
              />
            </>
          ) : null}
          {/* List Of Subcontractors */}
          {slide1.onNativeLand === true ? (
            <>
              <AttachFileField
                idProp="listOfSubcontractors"
                label="List of Subcontractors"
                state={slide9}
                setState={setSlide9}
                val={slide9.listOfSubcontractors.fileStatus}
                debouncedSave={slide9Mutation}
                disabledInput={disabledInputCheck(
                  "listOfSubcontractors",
                  disabledInputs,
                )}
                required={
                  formStateLoaded && slideState
                    ? slideState["listOfSubcontractors"].required
                    : slideState["listOfSubcontractors"].required
                }
              />
              {slide9.listOfSubcontractors.fileStatus === "Attached" ? (
                <>
                  <FileField
                    label="Upload List of Subcontractors"
                    idProp="listOfSubcontractors"
                    val={slide9.listOfSubcontractors.file}
                    state={slide9}
                    setState={setSlide9}
                    isUploading={isUploading}
                    setIsUploading={setIsUploading}
                  />
                </>
              ) : null}
            </>
          ) : null}
        </>
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};

export default FileSlide;
