import { useQuery } from "@tanstack/react-query";
import axios from "axios";

// Function to fetch predictive text by clientId (names, emails, titles and phone numbers)
export const fetchPredictiveText = async (clientId) => {
  try {
    const currentProject = await axios.get(
      `/api/predictive-text/${clientId}/`,
      {
        withCredentials: true,
      },
    );

    return currentProject.data;
  } catch (err) {
    console.log(err);
  }
};

// Custom hook to fetch predictive text
export default function usePredictiveText(clientId) {
  return useQuery({
    queryKey: ["predictiveText", clientId],
    queryFn: () => fetchPredictiveText(clientId),
    enabled: clientId !== null && clientId !== "" ? true : false,
  });
}
