import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useContext } from "react";
import { DashboardContext } from "../../../../DashboardContext/DashboardContextProvider";
import { FormContext } from "../../context/FormProvider";

// Function to submit form
// export const submitForm = async (
//   clientId,
//   projectId,
//   status,
//   userId,
//   slide10,
// ) => {
//   let data = {
//     requestStatus: status,
//     userId: userId,
//   };
//   if (status === "P") {
//     data["additionalInformation"] = slide10["additionalInformation"];
//     data["submitter"] = slide10["submitter"];
//     data["submitterPhone"] = slide10["submitterPhone"];
//     data["submitterEmail"] = slide10["submitterEmail"];
//     data["submitterTitle"] = slide10["submitterTitle"];
//   }
//   const res = await axios.patch(
//     `/api/update-form-status/${clientId}/${projectId}/`,
//     data,
//     { withCredentials: true },
//   );

//   return res.data;
// };

// // Custom hook to submit form
// export default function useSubmitForm(
//   clientId,
//   projectId,
//   status,
//   isReady,
//   setIsLoading,
//   setOpenSnackbar,
//   setSnackbarMessage,
//   setSnackbarSeverity,
//   createQuestionnaire,
//   notify,
// ) {
//   const { userId, queryClient, navigate, isAdmin } =
//     useContext(DashboardContext);
//   const formContext = useContext(FormContext);
//   const slide10 = formContext?.slide10 || null;

//   const submit = useMutation({
//     mutationFn: () => {
//       if (projectId === null || isReady === false) {
//         return;
//       } else {
//         return submitForm(clientId, projectId, status, userId, slide10);
//       }
//     },
//     onError: (err, variables, context) => {
//       if (projectId === null || isReady === false) {
//         setSnackbarMessage("");
//       }
//       setSnackbarSeverity("error");
//       setSnackbarMessage(
//         "There was an error submitting the form. Please try again.",
//       );
//       setOpenSnackbar(true);
//       setIsLoading(false);
//     },
//     onSuccess: () => {
//       setSnackbarMessage(
//         "Form submitted successfully. You will be redirected back to the home page.",
//       );
//       setSnackbarSeverity("success");
//       setOpenSnackbar(true);
//       createQuestionnaire();
//       notify({
//         clientId: clientId,
//         projectId: projectId,
//         location: "validate",
//       });
//     },
//     onSettled: () => {
//       // Invalidate and refetch query
//       if (projectId !== null && isReady === true) {
//         queryClient.invalidateQueries({
//           queryKey: ["currentProject", clientId, projectId],
//         });
//       }

//       queryClient.invalidateQueries({
//         queryKey: ["clientRows", clientId],
//       });

//       setIsLoading(false);
//     },
//   });

//   return submit;
// }

const submitForm = async (clientId, projectId, slide10) => {
  try {
    const res = await axios.patch(
      `/api/submit-form/${clientId}/${projectId}/`,
      {
        additionalInformation: slide10.additionalInformation,
        submitter: slide10.submitter,
        submitterPhone: slide10.submitterPhone,
        submitterEmail: slide10.submitterEmail,
        submitterTitle: slide10.submitterTitle,
      },
      { withCredentials: true },
    );

    console.log("SUBMIT FORM RESPONSE: ", res.data);
    return res.data;
  } catch (error) {
    console.log("SUBMIT FORM ERROR: ", error);
  }
};

export default function useSubmitForm(
  clientId,
  projectId,
  isReady,
  setIsReady,
  setOpenSnackbar,
  setSnackbarMessage,
  setSnackbarSeverity,
  setIsLoading,
  slide10,
) {
  const { queryClient, isAdmin } = useContext(DashboardContext);
  console.log("SLIDE 10: ", slide10);

  const submit = useMutation({
    mutationFn: () => {
      if (isReady === false) {
        return;
      } else {
        return submitForm(clientId, projectId, slide10);
      }
    },
    onError: (error) => {
      setSnackbarSeverity("error");
      setSnackbarMessage(
        "Error: There was an error submitting the form. Please try again.",
      );
      setOpenSnackbar(true);
      setIsReady(false);
    },
    onSuccess: (data) => {
      setSnackbarMessage(
        "Form submitted successfully. You will be redirected to the dashboard shortly.",
      );
      setSnackbarSeverity("success");
      setOpenSnackbar(true);
    },
    onSettled: () => {
      setIsLoading(false);
      queryClient.invalidateQueries(["currentProject", clientId, projectId]);
      if (!isAdmin) {
        queryClient.invalidateQueries(["clientRows", clientId, projectId]);
      } else {
        queryClient.invalidateQueries(["adminRows"]);
      }
    },
  });

  return submit;
}
