import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";
import {
  isValidEmail,
  isValidName,
  isValidPhoneNumber,
} from "../Helper/formbtnhelper.js";
import DateField from "../Inputs/DateField.jsx";
import EmailField from "../Inputs/EmailField";
import PhoneField from "../Inputs/PhoneField";
import RadioGroupField from "../Inputs/RadioGroupField";
import TextField from "../Inputs/TextField.jsx";
import TitleField from "../Inputs/TitleField.jsx";
import { FormContext } from "../context/FormProvider";
import { scrollToFirstRequiredField } from "../main-helper.js";
import useSetSlide from "./Hooks/setSlide";
import useInvalidInputs from "./Hooks/useInvalidInputs";

// Slide 5: Project Information
const ProjectInformation = ({
  setSlideStatus,
  slideState,
  formStateLoaded,
}) => {
  const [disabledInputs, setDisabledInputs] = useState([]);

  const {
    slide1,
    slide5,
    setSlide5,
    setIsLoading,
    requestStatus,
    names,
    phoneNumbers,
    emails,
    titles,
    companies,
    predictiveTextFetched,
    populateInvalidInputs,
    getInvalidData,
    getInputMessage,
    disabledInputCheck,
  } = useContext(FormContext);

  const { clientId, projectId } = useContext(DashboardContext);

  // On Mount, the page should scroll to the top
  useEffect(() => {
    scrollToFirstRequiredField(slide5);
  }, []);

  // Update Slide State
  const slide5Mutation = useSetSlide(
    clientId,
    projectId,
    5,
    setIsLoading,
    setSlideStatus,
  );

  // Get Invalid Inputs
  const invalidInputsData = useInvalidInputs(
    clientId,
    projectId,
    requestStatus,
  );

  // Populate Invalid Inputs
  useEffect(() => {
    populateInvalidInputs(
      invalidInputsData,
      disabledInputs,
      setDisabledInputs,
      "5",
    );
  }, [invalidInputsData.data, invalidInputsData.isFetched]);

  return (
    <>
      {formStateLoaded ? (
        <>
          <DateField
            idProp="startDate"
            label="Start Date"
            state={slide5}
            setState={setSlide5}
            val={slide5.startDate}
            debouncedSave={slide5Mutation}
            setIsLoading={setIsLoading}
            req={
              slide5.startDate === "" ||
              (slide5.endDate !== "" &&
                dayjs(slide5.startDate).isAfter(slide5.endDate))
            }
            disabledInput={disabledInputCheck("startDate", disabledInputs)}
            disabledInputMessage={
              disabledInputCheck("startDate", disabledInputs) === false
                ? getInputMessage("startDate", invalidInputsData, "5")
                : ""
            }
            invalidData={getInvalidData("startDate", invalidInputsData, "5")}
          />
          <DateField
            idProp="endDate"
            label="Estimated End Date"
            state={slide5}
            setState={setSlide5}
            val={slide5.endDate}
            debouncedSave={slide5Mutation}
            setIsLoading={setIsLoading}
            req={
              slide5.endDate === "" ||
              dayjs(slide5.endDate).isBefore(slide5.startDate)
            }
            disabledInput={disabledInputCheck("endDate", disabledInputs)}
            disabledInputMessage={
              disabledInputCheck("endDate", disabledInputs) === false
                ? getInputMessage("endDate", invalidInputsData, "5")
                : ""
            }
            invalidData={getInvalidData("endDate", invalidInputsData, "5")}
          />
          <TextField
            idProp="emergencyContactName"
            label="24 Hour Contact"
            val={slide5.emergencyContactName}
            state={slide5}
            setState={setSlide5}
            debouncedSave={slide5Mutation}
            setIsLoading={setIsLoading}
            autoData={predictiveTextFetched ? names : []}
            req={!isValidName(slide5.emergencyContactName, false)}
            type="name"
            disabledInput={disabledInputCheck(
              "emergencyContactName",
              disabledInputs,
            )}
            disabledInputMessage={
              disabledInputCheck("emergencyContactName", disabledInputs) ===
              false
                ? getInputMessage(
                    "emergencyContactName",
                    invalidInputsData,
                    "5",
                  )
                : ""
            }
            invalidData={getInvalidData(
              "emergencyContactName",
              invalidInputsData,
              "5",
            )}
          />
          <PhoneField
            idProp="emergencyContactPhone"
            label="Contact's Phone"
            val={slide5.emergencyContactPhone}
            state={slide5}
            setState={setSlide5}
            debouncedSave={slide5Mutation}
            setIsLoading={setIsLoading}
            autoData={predictiveTextFetched ? phoneNumbers : []}
            req={!isValidPhoneNumber(slide5.emergencyContactPhone, false)}
            disabledInput={disabledInputCheck(
              "emergencyContactPhone",
              disabledInputs,
            )}
            disabledInputMessage={
              disabledInputCheck("emergencyContactPhone", disabledInputs) ===
              false
                ? getInputMessage(
                    "emergencyContactPhone",
                    invalidInputsData,
                    "5",
                  )
                : ""
            }
            invalidData={getInvalidData(
              "emergencyContactPhone",
              invalidInputsData,
              "5",
            )}
          />
          <EmailField
            idProp="emergencyContactEmail"
            label="Contact's Email"
            val={slide5.emergencyContactEmail}
            state={slide5}
            setState={setSlide5}
            debouncedSave={slide5Mutation}
            setIsLoading={setIsLoading}
            autoData={predictiveTextFetched ? emails : []}
            req={!isValidEmail(slide5.emergencyContactEmail, false)}
            disabledInput={disabledInputCheck(
              "emergencyContactEmail",
              disabledInputs,
            )}
            disabledInputMessage={
              disabledInputCheck("emergencyContactEmail", disabledInputs) ===
              false
                ? getInputMessage(
                    "emergencyContactEmail",
                    invalidInputsData,
                    "5",
                  )
                : ""
            }
            invalidData={getInvalidData(
              "emergencyContactEmail",
              invalidInputsData,
              "5",
            )}
          />
          <TitleField
            idProp="emergencyContactTitle"
            label="Contact's Title"
            val={slide5.emergencyContactTitle}
            state={slide5}
            setState={setSlide5}
            debouncedSave={slide5Mutation}
            setIsLoading={setIsLoading}
            autoData={predictiveTextFetched ? titles : []}
            req={
              slide5.emergencyContactTitle === "" ||
              slide5.emergencyContactTitle.length < 2
            }
            disabledInput={disabledInputCheck(
              "emergencyContactTitle",
              disabledInputs,
            )}
            disabledInputMessage={
              disabledInputCheck("emergencyContactTitle", disabledInputs) ===
              false
                ? getInputMessage(
                    "emergencyContactTitle",
                    invalidInputsData,
                    "5",
                  )
                : ""
            }
            invalidData={getInvalidData(
              "emergencyContactTitle",
              invalidInputsData,
              "5",
            )}
          />
          {slide1.onNativeLand ? (
            <>
              <RadioGroupField
                idProp="prevAgricultureLand"
                label="Land Previously Used for Agriculture"
                val={slide5.prevAgricultureLand}
                state={slide5}
                setState={setSlide5}
                debouncedSave={slide5Mutation}
                setIsLoading={setIsLoading}
                disabledInput={disabledInputCheck(
                  "prevAgricultureLand",
                  disabledInputs,
                )}
              />
              <RadioGroupField
                idProp="demolitionActivities"
                label="Onsite Demolition Activities"
                val={slide5.demolitionActivities}
                state={slide5}
                setState={setSlide5}
                debouncedSave={slide5Mutation}
                setIsLoading={setIsLoading}
                disabledInput={disabledInputCheck(
                  "demolitionActivities",
                  disabledInputs,
                )}
              />
              <RadioGroupField
                idProp="dewateringDischarge"
                label="Discharging Dewatering Water"
                val={slide5.dewateringDischarge}
                state={slide5}
                setState={setSlide5}
                debouncedSave={slide5Mutation}
                setIsLoading={setIsLoading}
                disabledInput={disabledInputCheck(
                  "dewateringDischarge",
                  disabledInputs,
                )}
              />
            </>
          ) : null}
          <RadioGroupField
            idProp="layDownYard"
            label="Laydown Yard"
            val={slide5.layDownYard}
            state={slide5}
            setState={setSlide5}
            debouncedSave={slide5Mutation}
            setIsLoading={setIsLoading}
            disabledInput={disabledInputCheck("layDownYard", disabledInputs)}
          />
          {slide5.layDownYard ? (
            <RadioGroupField
              idProp="layDownYardDist"
              label="Included on Disturbed Acreage?"
              val={slide5.layDownYard === true ? slide5.layDownYardDist : false}
              state={slide5}
              setState={setSlide5}
              debouncedSave={slide5Mutation}
              setIsLoading={setIsLoading}
              disabledInput={disabledInputCheck(
                "layDownYardDist",
                disabledInputs,
              )}
            />
          ) : null}
          <RadioGroupField
            idProp="borrowArea"
            label="Onsite Plant/Borrow Area"
            val={slide5.borrowArea}
            state={slide5}
            setState={setSlide5}
            debouncedSave={slide5Mutation}
            setIsLoading={setIsLoading}
            disabledInput={disabledInputCheck("borrowArea", disabledInputs)}
          />
          <RadioGroupField
            idProp="portableTanksOnSite"
            label="On-Site Portable Fuel Tanks"
            val={slide5.portableTanksOnSite}
            state={slide5}
            setState={setSlide5}
            debouncedSave={slide5Mutation}
            setIsLoading={setIsLoading}
            disabledInput={disabledInputCheck(
              "portableTanksOnSite",
              disabledInputs,
            )}
          />
          {slide5.portableTanksOnSite ? (
            <TextField
              idProp="portableTankCapacity"
              label="Tank(s) Capacity"
              state={slide5}
              setState={setSlide5}
              val={slide5.portableTankCapacity}
              debouncedSave={slide5Mutation}
              setIsLoading={setIsLoading}
              req={
                slide5.portableTanksOnSite && slide5.portableTankCapacity === ""
              }
              disabledInput={disabledInputCheck(
                "portableTankCapacity",
                disabledInputs,
              )}
              disabledInputMessage={
                disabledInputCheck("portableTankCapacity", disabledInputs) ===
                false
                  ? getInputMessage(
                      "portableTankCapacity",
                      invalidInputsData,
                      "5",
                    )
                  : ""
              }
              invalidData={getInvalidData(
                "portableTankCapacity",
                invalidInputsData,
                "5",
              )}
            />
          ) : null}
          <>
            <TextField
              idProp="bmpCompanyName"
              label="BMP Installer"
              val={slide5.bmpCompanyName}
              state={slide5}
              setState={setSlide5}
              debouncedSave={slide5Mutation}
              setIsLoading={setIsLoading}
              autoData={predictiveTextFetched ? companies : []}
              req={
                slide5.bmpCompanyName === "" || slide5.bmpCompanyName.length < 2
              }
              type="company"
              disabledInput={disabledInputCheck(
                "bmpCompanyName",
                disabledInputs,
              )}
              disabledInputMessage={
                disabledInputCheck("bmpCompanyName", disabledInputs) === false
                  ? getInputMessage("bmpCompanyName", invalidInputsData, "5")
                  : ""
              }
              invalidData={getInvalidData(
                "bmpCompanyName",
                invalidInputsData,
                "5",
              )}
            />
            <TextField
              idProp="bmpContactName"
              label="Contact Name"
              val={slide5.bmpContactName}
              state={slide5}
              setState={setSlide5}
              debouncedSave={slide5Mutation}
              setIsLoading={setIsLoading}
              autoData={predictiveTextFetched ? names : []}
              req={!isValidName(slide5.bmpContactName, false)}
              type="name"
              disabledInput={disabledInputCheck(
                "bmpContactName",
                disabledInputs,
              )}
              disabledInputMessage={
                disabledInputCheck("bmpContactName", disabledInputs) === false
                  ? getInputMessage("bmpContactName", invalidInputsData, "5")
                  : ""
              }
              invalidData={getInvalidData(
                "bmpContactName",
                invalidInputsData,
                "5",
              )}
            />
            <PhoneField
              idProp="bmpContactPhone"
              label="Contact's Phone"
              val={slide5.bmpContactPhone}
              state={slide5}
              setState={setSlide5}
              debouncedSave={slide5Mutation}
              setIsLoading={setIsLoading}
              autoData={predictiveTextFetched ? phoneNumbers : []}
              req={!isValidPhoneNumber(slide5.bmpContactPhone, false)}
              disabledInput={disabledInputCheck(
                "bmpContactPhone",
                disabledInputs,
              )}
              disabledInputMessage={
                disabledInputCheck("bmpContactPhone", disabledInputs) === false
                  ? getInputMessage("bmpContactPhone", invalidInputsData, "5")
                  : ""
              }
              invalidData={getInvalidData(
                "bmpContactPhone",
                invalidInputsData,
                "5",
              )}
            />
            <EmailField
              idProp="bmpContactEmail"
              label="Contact's Email"
              val={slide5.bmpContactEmail}
              state={slide5}
              setState={setSlide5}
              debouncedSave={slide5Mutation}
              setIsLoading={setIsLoading}
              autoData={predictiveTextFetched ? emails : []}
              req={!isValidEmail(slide5.bmpContactEmail, false)}
              disabledInput={disabledInputCheck(
                "bmpContactEmail",
                disabledInputs,
              )}
              disabledInputMessage={
                disabledInputCheck("bmpContactEmail", disabledInputs) === false
                  ? getInputMessage("bmpContactEmail", invalidInputsData, "5")
                  : ""
              }
              invalidData={getInvalidData(
                "bmpContactEmail",
                invalidInputsData,
                "5",
              )}
            />
          </>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};

export default ProjectInformation;
