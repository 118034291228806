import React, { useContext, useEffect, useState } from "react";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";
import {
  isValidEmail,
  isValidName,
  isValidPhoneNumber,
} from "../Helper/formbtnhelper.js";
import EmailField from "../Inputs/EmailField";
import PhoneField from "../Inputs/PhoneField";
import RadioGroupField from "../Inputs/RadioGroupField";
import TextField from "../Inputs/TextField.jsx";
import TitleField from "../Inputs/TitleField.jsx";
import { FormContext } from "../context/FormProvider";
import { scrollToFirstRequiredField } from "../main-helper.js";
import useSetSlide from "./Hooks/setSlide";
import useInvalidInputs from "./Hooks/useInvalidInputs";

// Slide 7: Owner Information
const OwnerInformation = ({ setSlideStatus, slideState, formStateLoaded }) => {
  const [disabledInputs, setDisabledInputs] = useState([]);
  const {
    slide1,
    slide7,
    setSlide7,
    slide8,
    setSlide8,
    setIsLoading,
    requestStatus,
    names,
    phoneNumbers,
    emails,
    titles,
    companies,
    regulatoryAgencies,
    predictiveTextFetched,
    populateInvalidInputs,
    getInvalidData,
    getInputMessage,
    disabledInputCheck,
    disableAll,
  } = useContext(FormContext);

  const { clientId, projectId } = useContext(DashboardContext);

  // On Mount, the page should scroll to the top
  useEffect(() => {
    scrollToFirstRequiredField(slide7);
  }, []);

  // Update Slide State
  const slide7Mutation = useSetSlide(
    clientId,
    projectId,
    7,
    setIsLoading,
    setSlideStatus,
  );
  // GET INVALID INPUTS
  const invalidInputsData = useInvalidInputs(
    clientId,
    projectId,
    requestStatus,
  );

  // Autofill Regulatory Agency Information if applicable
  useEffect(() => {
    if (disableAll) return;
    if (slide7.oName === "" || slide7.oName.length < 4) return;
    for (const agency in regulatoryAgencies) {
      if (
        agency === slide7.oName &&
        slide7.oPhone === "" &&
        slide7.oAdd === "" &&
        slide7.oCity === "" &&
        slide7.oZip === ""
      ) {
        let newState = {
          ...slide7,
          oPhone: regulatoryAgencies[agency].phone,
          oAdd: regulatoryAgencies[agency].address,
          oCity: regulatoryAgencies[agency].city,
          oZip: regulatoryAgencies[agency].zip,
        };
        setSlide7(newState);
        slide7Mutation.mutate(newState);
      }
    }
  }, [slide7.oName]);

  // Populate Invalid Inputs

  useEffect(() => {
    populateInvalidInputs(
      invalidInputsData,
      disabledInputs,
      setDisabledInputs,
      "7",
    );
  }, [invalidInputsData.isFetched]);

  return (
    <>
      {formStateLoaded ? (
        <>
          <p className="general-info">
            Please provide the following information about the projects owning
            entity. All fields should match exactly as registered with the AZCC
            when forming the entity
          </p>
          <TextField
            idProp="oName"
            label="Company Name"
            state={slide7}
            setState={setSlide7}
            val={slide7.oName}
            debouncedSave={slide7Mutation}
            setIsLoading={setIsLoading}
            autoData={predictiveTextFetched ? companies : []}
            req={slide7.oName === "" || slide7.oName.length < 3}
            disabledInput={disabledInputCheck("oName", disabledInputs)}
            disabledInputMessage={
              disabledInputCheck("oName", disabledInputs) === false
                ? getInputMessage("oName", invalidInputsData, "7")
                : ""
            }
            invalidData={getInvalidData("oName", invalidInputsData, "7")}
          />
          <TextField
            idProp="oAdd"
            label="Street Address"
            state={slide7}
            setState={setSlide7}
            val={slide7.oAdd}
            debouncedSave={slide7Mutation}
            setIsLoading={setIsLoading}
            req={slide7.oAdd === "" || slide7.oAdd.length < 6}
            disabledInput={disabledInputCheck("oAdd", disabledInputs)}
            disabledInputMessage={
              disabledInputCheck("oAdd", disabledInputs) === false
                ? getInputMessage("oAdd", invalidInputsData, "7")
                : ""
            }
            invalidData={getInvalidData("oAdd", invalidInputsData, "7")}
          />
          <TextField
            idProp="oCity"
            label="City"
            state={slide7}
            setState={setSlide7}
            val={slide7.oCity}
            debouncedSave={slide7Mutation}
            setIsLoading={setIsLoading}
            req={slide7.oCity === "" || slide7.oCity.length < 3}
            disabledInput={disabledInputCheck("oCity", disabledInputs)}
            disabledInputMessage={
              disabledInputCheck("oCity", disabledInputs) === false
                ? getInputMessage("oCity", invalidInputsData, "7")
                : ""
            }
            invalidData={getInvalidData("oCity", invalidInputsData, "7")}
          />
          <TextField
            idProp="oZip"
            label="Zip"
            state={slide7}
            setState={setSlide7}
            val={slide7.oZip}
            debouncedSave={slide7Mutation}
            setIsLoading={setIsLoading}
            req={slide7.oZip === "" || slide7.oZip.length !== 5}
            disabledInput={disabledInputCheck("oZip", disabledInputs)}
            disabledInputMessage={
              disabledInputCheck("oZip", disabledInputs) === false
                ? getInputMessage("oZip", invalidInputsData, "7")
                : ""
            }
            invalidData={getInvalidData("oZip", invalidInputsData, "7")}
          />
          <PhoneField
            idProp="oPhone"
            label="Office Phone"
            state={slide7}
            setState={setSlide7}
            val={slide7.oPhone}
            debouncedSave={slide7Mutation}
            setIsLoading={setIsLoading}
            autoData={predictiveTextFetched ? phoneNumbers : []}
            req={!isValidPhoneNumber(slide7.oPhone, false)}
            disabledInput={disabledInputCheck("oPhone", disabledInputs)}
            disabledInputMessage={
              disabledInputCheck("oPhone", disabledInputs) === false
                ? getInputMessage("oPhone", invalidInputsData, "7")
                : ""
            }
            invalidData={getInvalidData("oPhone", invalidInputsData, "7")}
          />
          <RadioGroupField
            idProp="ownerGCCheck"
            label="Will the Owner also be the General Contractor?"
            state={slide7}
            setState={setSlide7}
            slide8={slide8}
            setSlide8={setSlide8}
            val={slide7.ownerGCCheck}
            debouncedSave={slide7Mutation}
            setIsLoading={setIsLoading}
            disabledInput={disabledInputCheck("ownerGCCheck", disabledInputs)}
          />
          {+slide1.distAcr >= 1 ? (
            <>
              <p className="general-info">
                {
                  "Please provide the contact information of the individual who has the authority to make legal decisions on behalf of the owning entity"
                }
              </p>
              <TextField
                idProp="oContactName"
                label="Owner's Responsible Officer"
                state={slide7}
                setState={setSlide7}
                val={slide7.oContactName}
                debouncedSave={slide7Mutation}
                setIsLoading={setIsLoading}
                autoData={predictiveTextFetched ? names : []}
                type="name"
                req={!isValidName(slide7.oContactName, false)}
                disabledInput={disabledInputCheck(
                  "oContactName",
                  disabledInputs,
                )}
                disabledInputMessage={
                  disabledInputCheck("oContactName", disabledInputs) === false
                    ? getInputMessage("oContactName", invalidInputsData, "7")
                    : ""
                }
                invalidData={getInvalidData(
                  "oContactName",
                  invalidInputsData,
                  "7",
                )}
              />
              <TitleField
                idProp="oContactTitle"
                label="Responsible Officer's Title"
                state={slide7}
                setState={setSlide7}
                val={slide7.oContactTitle}
                debouncedSave={slide7Mutation}
                setIsLoading={setIsLoading}
                autoData={predictiveTextFetched ? titles : []}
                type="title"
                req={
                  slide7.oContactTitle === "" || slide7.oContactTitle.length < 3
                }
                disabledInput={disabledInputCheck(
                  "oContactTitle",
                  disabledInputs,
                )}
                disabledInputMessage={
                  disabledInputCheck("oContactTitle", disabledInputs) === false
                    ? getInputMessage("oContactTitle", invalidInputsData, "7")
                    : ""
                }
                invalidData={getInvalidData(
                  "oContactTitle",
                  invalidInputsData,
                  "7",
                )}
              />
              <PhoneField
                idProp="oContactPhone"
                label="Responsible Officer's Phone"
                state={slide7}
                setState={setSlide7}
                val={slide7.oContactPhone}
                debouncedSave={slide7Mutation}
                setIsLoading={setIsLoading}
                autoData={predictiveTextFetched ? phoneNumbers : []}
                req={!isValidPhoneNumber(slide7.oContactPhone, false)}
                disabledInput={disabledInputCheck(
                  "oContactPhone",
                  disabledInputs,
                )}
                disabledInputMessage={
                  disabledInputCheck("oContactPhone", disabledInputs) === false
                    ? getInputMessage("oContactPhone", invalidInputsData, "7")
                    : ""
                }
                invalidData={getInvalidData(
                  "oContactPhone",
                  invalidInputsData,
                  "7",
                )}
              />
              <EmailField
                idProp="oContactEmail"
                label="Responsible Officer's Email"
                state={slide7}
                setState={setSlide7}
                val={slide7.oContactEmail}
                debouncedSave={slide7Mutation}
                setIsLoading={setIsLoading}
                autoData={predictiveTextFetched ? emails : []}
                req={!isValidEmail(slide7.oContactEmail, false)}
                disabledInput={disabledInputCheck(
                  "oContactEmail",
                  disabledInputs,
                )}
                disabledInputMessage={
                  disabledInputCheck("oContactEmail", disabledInputs) === false
                    ? getInputMessage("oContactEmail", invalidInputsData, "7")
                    : ""
                }
                invalidData={getInvalidData(
                  "oContactEmail",
                  invalidInputsData,
                  "7",
                )}
              />
              <RadioGroupField
                idProp="delegatedRepCheck"
                label="Delegated Authority"
                state={slide7}
                setState={setSlide7}
                val={slide7.delegatedRepCheck}
                debouncedSave={slide7Mutation}
                setIsLoading={setIsLoading}
                disabledInput={disabledInputCheck(
                  "delegatedRepCheck",
                  disabledInputs,
                )}
              />
              {slide7.delegatedRepCheck ? (
                <>
                  <p className="general-info">
                    {
                      "Please provide the contact information of the individual who has been delegated the authority to make legal decisions and act on behalf of the responsible officer "
                    }
                  </p>
                  <TextField
                    idProp="odrCompany"
                    label="Delegated Company Name"
                    state={slide7}
                    setState={setSlide7}
                    val={slide7.odrCompany}
                    debouncedSave={slide7Mutation}
                    setIsLoading={setIsLoading}
                    autoData={predictiveTextFetched ? companies : []}
                    type="company"
                    req={
                      slide7.delegatedRepCheck &&
                      (slide7.odrCompany === "" || slide7.odrCompany.length < 3)
                    }
                    disabledInput={disabledInputCheck(
                      "odrCompany",
                      disabledInputs,
                    )}
                    disabledInputMessage={
                      disabledInputCheck("odrCompany", disabledInputs) === false
                        ? getInputMessage("odrCompany", invalidInputsData, "7")
                        : ""
                    }
                    invalidData={getInvalidData(
                      "odrCompany",
                      invalidInputsData,
                      "7",
                    )}
                  />
                  <TextField
                    idProp="odrName"
                    label="Delegated Representative"
                    state={slide7}
                    setState={setSlide7}
                    val={slide7.odrName}
                    debouncedSave={slide7Mutation}
                    setIsLoading={setIsLoading}
                    autoData={predictiveTextFetched ? names : []}
                    type="name"
                    req={
                      slide7.delegatedRepCheck &&
                      !isValidName(slide7.odrName, false)
                    }
                    disabledInput={disabledInputCheck(
                      "odrName",
                      disabledInputs,
                    )}
                    disabledInputMessage={
                      disabledInputCheck("odrName", disabledInputs) === false
                        ? getInputMessage("odrName", invalidInputsData, "7")
                        : ""
                    }
                    invalidData={getInvalidData(
                      "odrName",
                      invalidInputsData,
                      "7",
                    )}
                  />
                  <TitleField
                    idProp="odrTitle"
                    label="Representative's Title"
                    state={slide7}
                    setState={setSlide7}
                    val={slide7.odrTitle}
                    debouncedSave={slide7Mutation}
                    setIsLoading={setIsLoading}
                    autoData={predictiveTextFetched ? titles : []}
                    type="title"
                    req={
                      slide7.delegatedRepCheck &&
                      (slide7.odrTitle === "" || slide7.odrTitle.length < 3)
                    }
                    disabledInput={disabledInputCheck(
                      "odrTitle",
                      disabledInputs,
                    )}
                    disabledInputMessage={
                      disabledInputCheck("odrTitle", disabledInputs) === false
                        ? getInputMessage("odrTitle", invalidInputsData, "7")
                        : ""
                    }
                    invalidData={getInvalidData(
                      "odrTitle",
                      invalidInputsData,
                      "7",
                    )}
                  />
                  <PhoneField
                    idProp="odrPhone"
                    label="Representative's Phone"
                    state={slide7}
                    setState={setSlide7}
                    val={slide7.odrPhone}
                    debouncedSave={slide7Mutation}
                    setIsLoading={setIsLoading}
                    autoData={predictiveTextFetched ? phoneNumbers : []}
                    req={
                      slide7.delegatedRepCheck &&
                      !isValidPhoneNumber(slide7.odrPhone, false)
                    }
                    disabledInput={disabledInputCheck(
                      "odrPhone",
                      disabledInputs,
                    )}
                    disabledInputMessage={
                      disabledInputCheck("odrPhone", disabledInputs) === false
                        ? getInputMessage("odrPhone", invalidInputsData, "7")
                        : ""
                    }
                    invalidData={getInvalidData(
                      "odrPhone",
                      invalidInputsData,
                      "7",
                    )}
                  />
                  <EmailField
                    idProp="odrEmail"
                    label="Representative's Email"
                    state={slide7}
                    setState={setSlide7}
                    val={slide7.odrEmail}
                    debouncedSave={slide7Mutation}
                    setIsLoading={setIsLoading}
                    autoData={predictiveTextFetched ? emails : []}
                    req={
                      slide7.delegatedRepCheck &&
                      !isValidEmail(slide7.odrEmail, false)
                    }
                    disabledInput={disabledInputCheck(
                      "odrEmail",
                      disabledInputs,
                    )}
                    disabledInputMessage={
                      disabledInputCheck("odrEmail", disabledInputs) === false
                        ? getInputMessage("odrEmail", invalidInputsData, "7")
                        : ""
                    }
                    invalidData={getInvalidData(
                      "odrEmail",
                      invalidInputsData,
                      "7",
                    )}
                  />
                </>
              ) : null}
            </>
          ) : (
            <>
              <TextField
                idProp="wsNumber"
                label="Water Services Account Number"
                state={slide7}
                setState={setSlide7}
                val={slide7.wsNumber}
                debouncedSave={slide7Mutation}
                setIsLoading={setIsLoading}
                disabledInput={disabledInputCheck("wsNumber", disabledInputs)}
                disabledInputMessage={
                  disabledInputCheck("wsNumber", disabledInputs) === false
                    ? getInputMessage("wsNumber", invalidInputsData, "7")
                    : ""
                }
                invalidData={getInvalidData("wsNumber", invalidInputsData, "7")}
              />
              <TextField
                idProp="waterServicesContact"
                label="Water Services Contact Name"
                state={slide7}
                setState={setSlide7}
                val={slide7.waterServicesContact}
                debouncedSave={slide7Mutation}
                setIsLoading={setIsLoading}
                autoData={predictiveTextFetched ? names : []}
                type="name"
                disabledInput={disabledInputCheck(
                  "waterServicesContact",
                  disabledInputs,
                )}
                disabledInputMessage={
                  disabledInputCheck("waterServicesContact", disabledInputs) ===
                  false
                    ? getInputMessage(
                        "waterServicesContact",
                        invalidInputsData,
                        "7",
                      )
                    : ""
                }
                invalidData={getInvalidData(
                  "waterServicesContact",
                  invalidInputsData,
                  "7",
                )}
              />
              <PhoneField
                idProp="waterServicesPhone"
                label="Water Services Contact Phone"
                state={slide7}
                setState={setSlide7}
                val={slide7.waterServicesPhone}
                debouncedSave={slide7Mutation}
                setIsLoading={setIsLoading}
                autoData={predictiveTextFetched ? phoneNumbers : []}
                disabledInput={disabledInputCheck(
                  "waterServicesPhone",
                  disabledInputs,
                )}
                disabledInputMessage={
                  disabledInputCheck("waterServicesPhone", disabledInputs) ===
                  false
                    ? getInputMessage(
                        "waterServicesPhone",
                        invalidInputsData,
                        "7",
                      )
                    : ""
                }
                invalidData={getInvalidData(
                  "waterServicesPhone",
                  invalidInputsData,
                  "7",
                )}
              />
              <EmailField
                idProp="waterServicesEmail"
                label="Water Services Contact Email"
                state={slide7}
                setState={setSlide7}
                val={slide7.waterServicesEmail}
                debouncedSave={slide7Mutation}
                setIsLoading={setIsLoading}
                autoData={predictiveTextFetched ? emails : []}
                disabledInput={disabledInputCheck(
                  "waterServicesEmail",
                  disabledInputs,
                )}
                disabledInputMessage={
                  disabledInputCheck("waterServicesEmail", disabledInputs) ===
                  false
                    ? getInputMessage(
                        "waterServicesEmail",
                        invalidInputsData,
                        "7",
                      )
                    : ""
                }
                invalidData={getInvalidData(
                  "waterServicesEmail",
                  invalidInputsData,
                  "7",
                )}
              />
            </>
          )}
        </>
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};

export default OwnerInformation;
