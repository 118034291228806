import React, { useContext, useEffect, useState } from "react";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";
import {
  isValidEmail,
  isValidName,
  isValidPhoneNumber,
} from "../Helper/formbtnhelper";
import EmailField from "../Inputs/EmailField";
import PhoneField from "../Inputs/PhoneField";
import RadioGroupField from "../Inputs/RadioGroupField";
import TextField from "../Inputs/TextField";
import TitleField from "../Inputs/TitleField";
import { FormContext } from "../context/FormProvider";
import { scrollToFirstRequiredField } from "../main-helper";
import "../styles/form-container.css";
import useSetSlide from "./Hooks/setSlide";
import useInvalidInputs from "./Hooks/useInvalidInputs";
import "./styles/generalinfo.css";

// Slide 1: General Information
const GeneralInfo = ({ setSlideStatus, formState, formStateLoaded }) => {
  const [disabledInputs, setDisabledInputs] = useState([]);
  const {
    slide1,
    setSlide1,
    slide2,
    setSlide2,
    setIsLoading,
    requestStatus,
    names,
    phoneNumbers,
    emails,
    titles,
    predictiveTextFetched,
    populateInvalidInputs,
    getInvalidData,
    getInputMessage,
    disabledInputCheck,
  } = useContext(FormContext);

  const { projectId, clientId } = useContext(DashboardContext);

  // - On Mount, the page should scroll to the top
  useEffect(() => {
    scrollToFirstRequiredField(slide1);
  }, []);

  // Update the slide state
  const slide1Mutation = useSetSlide(
    clientId,
    projectId,
    1,
    setIsLoading,
    setSlideStatus,
  );

  // Get Invalid Inputs
  const invalidInputsData = useInvalidInputs(
    clientId,
    projectId,
    requestStatus,
  );

  // Populate invalid inputs
  useEffect(() => {
    populateInvalidInputs(
      invalidInputsData,
      disabledInputs,
      setDisabledInputs,
      "1",
    );
  }, [invalidInputsData.data, invalidInputsData.isFetched]);

  return (
    <>
      <TextField
        idProp={"projectName"}
        label="Project Name"
        state={slide1}
        setState={setSlide1}
        val={slide1.projectName}
        req={slide1.projectName === ""}
        setIsLoading={setIsLoading}
        type="project"
        debouncedSave={projectId ? slide1Mutation : undefined}
        disabledInput={disabledInputCheck("projectName", disabledInputs)}
        disabledInputMessage={
          disabledInputCheck("projectName", disabledInputs) === false
            ? getInputMessage("projectName", invalidInputsData, "1")
            : ""
        }
        invalidData={getInvalidData("projectName", invalidInputsData, "1")}
      />
      <p className="general-info">
        {
          "Please provide the contact information for the individual to contact for this specific project"
        }
      </p>
      <TextField
        idProp={"pointOfContact"}
        label="Main Point of Contact"
        state={slide1}
        setState={setSlide1}
        val={slide1.pointOfContact}
        setIsLoading={setIsLoading}
        autoData={predictiveTextFetched ? names : []}
        req={!isValidName(slide1.pointOfContact, false)}
        type="name"
        debouncedSave={projectId ? slide1Mutation : undefined}
        disabledInput={disabledInputCheck("pointOfContact", disabledInputs)}
        disabledInputMessage={
          disabledInputCheck("pointOfContact", disabledInputs) === false
            ? getInputMessage("pointOfContact", invalidInputsData, "1")
            : ""
        }
        invalidData={getInvalidData("pointOfContact", invalidInputsData, "1")}
      />
      <PhoneField
        idProp={"pointOfContactPhone"}
        label="Main Contact Phone"
        state={slide1}
        setState={setSlide1}
        val={slide1.pointOfContactPhone}
        setIsLoading={setIsLoading}
        autoData={predictiveTextFetched ? phoneNumbers : []}
        req={!isValidPhoneNumber(slide1.pointOfContactPhone, false)}
        debouncedSave={projectId ? slide1Mutation : undefined}
        disabledInput={disabledInputCheck(
          "pointOfContactPhone",
          disabledInputs,
        )}
        disabledInputMessage={
          disabledInputCheck("pointOfContactPhone", disabledInputs) === false
            ? getInputMessage("pointOfContactPhone", invalidInputsData, "1")
            : ""
        }
        invalidData={getInvalidData(
          "pointOfContactPhone",
          invalidInputsData,
          "1",
        )}
      />
      <EmailField
        idProp={"pointOfContactEmail"}
        label="Main Contact Email"
        state={slide1}
        setState={setSlide1}
        val={slide1.pointOfContactEmail}
        setIsLoading={setIsLoading}
        autoData={predictiveTextFetched ? emails : []}
        req={!isValidEmail(slide1.pointOfContactEmail, false)}
        debouncedSave={projectId ? slide1Mutation : undefined}
        disabledInput={disabledInputCheck(
          "pointOfContactEmail",
          disabledInputs,
        )}
        disabledInputMessage={
          disabledInputCheck("pointOfContactEmail", disabledInputs) === false
            ? getInputMessage("pointOfContactEmail", invalidInputsData, "1")
            : ""
        }
        invalidData={getInvalidData(
          "pointOfContactEmail",
          invalidInputsData,
          "1",
        )}
      />
      <TitleField
        idProp={"pointOfContactTitle"}
        label="Main Contact Title"
        state={slide1}
        setState={setSlide1}
        val={slide1.pointOfContactTitle}
        setIsLoading={setIsLoading}
        autoData={titles}
        req={
          slide1.pointOfContactTitle === "" ||
          slide1.pointOfContactTitle.length < 2
        }
        debouncedSave={projectId ? slide1Mutation : undefined}
        disabledInput={disabledInputCheck(
          "pointOfContactTitle",
          disabledInputs,
        )}
        disabledInputMessage={
          disabledInputCheck("pointOfContactTitle", disabledInputs) === false
            ? getInputMessage("pointOfContactTitle", invalidInputsData, "1")
            : ""
        }
        invalidData={getInvalidData(
          "pointOfContactTitle",
          invalidInputsData,
          "1",
        )}
      />
      <p className="general-info">
        {"Please provide the total & disturbed acreage for the project"}
      </p>
      <TextField
        idProp={"totAcr"}
        label="Total Acreage"
        state={slide1}
        setState={setSlide1}
        val={slide1.totAcr}
        setIsLoading={setIsLoading}
        req={slide1.totAcr === ""}
        type="acreage"
        debouncedSave={projectId ? slide1Mutation : undefined}
        disabledInput={disabledInputCheck("totAcr", disabledInputs)}
        disabledInputMessage={
          disabledInputCheck("totAcr", disabledInputs) === false
            ? getInputMessage("totAcr", invalidInputsData, "1")
            : ""
        }
        invalidData={getInvalidData("totAcr", invalidInputsData, "1")}
      />
      <TextField
        idProp={"distAcr"}
        label="Acreage to be Disturbed"
        state={slide1}
        setState={setSlide1}
        val={slide1.distAcr}
        setIsLoading={setIsLoading}
        req={slide1.distAcr === "" || +slide1.distAcr > +slide1.totAcr}
        type="acreage"
        debouncedSave={projectId ? slide1Mutation : undefined}
        disabledInput={disabledInputCheck("distAcr", disabledInputs)}
        disabledInputMessage={
          disabledInputCheck("distAcr", disabledInputs) === false
            ? getInputMessage("distAcr", invalidInputsData, "1")
            : ""
        }
        invalidData={getInvalidData("distAcr", invalidInputsData, "1")}
      />
      <RadioGroupField
        idProp={"onNativeLand"}
        label="Is Project Located on Native or Protected Land?"
        state={slide1}
        setState={setSlide1}
        slide2={slide2}
        setSlide2={setSlide2}
        val={slide1.onNativeLand}
        setIsLoading={setIsLoading}
        debouncedSave={projectId ? slide1Mutation : undefined}
        disabledInput={disabledInputCheck("onNativeLand", disabledInputs)}
      />
      <RadioGroupField
        idProp={"proposalApproved"}
        label="Approved Proposal from Argus?"
        value={slide1.proposalApproved}
        state={slide1}
        setState={setSlide1}
        val={slide1.proposalApproved}
        setIsLoading={setIsLoading}
        debouncedSave={projectId ? slide1Mutation : undefined}
        disabledInput={disabledInputCheck("proposalApproved", disabledInputs)}
      />
      {slide1.proposalApproved === false ? (
        <p id="general-info-check">
          Unable to continue, until the proposal has been approved
        </p>
      ) : (
        <>
          <TextField
            idProp="jobNumber"
            label="PO/Job Number"
            val={slide1.jobNumber}
            state={slide1}
            setState={setSlide1}
            setIsLoading={setIsLoading}
            req={slide1.jobNumber === ""}
            type="jobNumber"
            debouncedSave={projectId ? slide1Mutation : undefined}
            disabledInput={disabledInputCheck("jobNumber", disabledInputs)}
            disabledInputMessage={
              disabledInputCheck("jobNumber", disabledInputs) === false
                ? getInputMessage("jobNumber", invalidInputsData, "1")
                : ""
            }
            invalidData={getInvalidData("jobNumber", invalidInputsData, "1")}
          />
          <EmailField
            idProp="accountingEmail"
            label="Accounting Contact's Email"
            state={slide1}
            setState={setSlide1}
            val={slide1.accountingEmail}
            setIsLoading={setIsLoading}
            autoData={predictiveTextFetched ? emails : []}
            req={!isValidEmail(slide1.accountingEmail, false)}
            debouncedSave={projectId ? slide1Mutation : undefined}
            disabledInput={disabledInputCheck(
              "accountingEmail",
              disabledInputs,
            )}
            disabledInputMessage={
              disabledInputCheck("accountingEmail", disabledInputs) === false
                ? getInputMessage("accountingEmail", invalidInputsData, "1")
                : ""
            }
            invalidData={getInvalidData(
              "accountingEmail",
              invalidInputsData,
              "1",
            )}
          />
        </>
      )}
    </>
  );
};

export default GeneralInfo;
