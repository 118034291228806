import React, { useContext, useEffect, useState } from "react";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";
import { FormContext } from "../context/FormProvider";
import { scrollToFirstRequiredField } from "../main-helper";
import "../styles/form-container.css";
import useSetSlide from "./Hooks/setSlide";
import useInvalidInputs from "./Hooks/useInvalidInputs";
import "./styles/additionalinformation.css";

// Slide 10: Additional Information
const AdditionalInformation = ({
  setSlideStatus,
  slideState,
  formStateLoaded,
}) => {
  const [disabledInputs, setDisabledInputs] = useState([]);
  const {
    slide10,
    setSlide10,
    setIsLoading,
    requestStatus,
    populateInvalidInputs,
    disabledInputCheck,
  } = useContext(FormContext);

  const { projectId, clientId, user } = useContext(DashboardContext);

  // On Mount, the page should scroll to the top
  useEffect(() => {
    scrollToFirstRequiredField(slide10);
  }, []);

  useEffect(() => {
    if (user.id !== "argus_admin" && slide10.submitter !== user.name) {
      setSlide10({
        ...slide10,
        submitter: user.name,
        submitterEmail: user.email,
        submitterPhone: user.phone,
        submitterTitle:
          slide10.submitterTitle === "" && user.title !== ""
            ? user.title
            : slide10.submitterTitle,
      });
    }
  }, []);

  // Update Slide Logic
  const slide10Mutation = useSetSlide(
    clientId,
    projectId,
    10,
    setIsLoading,
    setSlideStatus,
  );

  // - Additional Information Event Handler -

  const handleTextAreaChange = (e) => {
    let newState = { ...slide10, [e.target.id]: e.target.value };
    setSlide10(newState);
    setIsLoading(() => true);
    slide10Mutation.mutate(newState);
  };

  // : INVALID INPUTS LOGIC :

  const invalidInputsData = useInvalidInputs(
    clientId,
    projectId,
    requestStatus,
  );

  useEffect(() => {
    populateInvalidInputs(
      invalidInputsData,
      disabledInputs,
      setDisabledInputs,
      "10",
    );
  }, [invalidInputsData.data, invalidInputsData.isFetched]);

  return (
    <>
      {formStateLoaded ? (
        <>
          {/* <TextField
            idProp="submitter"
            label="Your Name"
            state={slide10}
            setState={setSlide10}
            val={slide10.submitter}
            debouncedSave={slide10Mutation}
            setIsLoading={setIsLoading}
            autoData={predictiveTextFetched ? names : []}
            req={!isValidName(slide10.submitter, false)}
            disabledInput={disabledInputCheck("submitter", disabledInputs)}
            type="name"
            disabledInputMessage={
              disabledInputCheck("submitter", disabledInputs) === false
                ? getInputMessage("submitter", invalidInputsData, "10")
                : ""
            }
            invalidData={getInvalidData("submitter", invalidInputsData, "10")}
          />
          <PhoneField
            idProp="submitterPhone"
            label="Your Phone Number"
            state={slide10}
            setState={setSlide10}
            val={slide10.submitterPhone}
            debouncedSave={slide10Mutation}
            setIsLoading={setIsLoading}
            autoData={predictiveTextFetched ? phoneNumbers : []}
            req={!isValidPhoneNumber(slide10.submitterPhone, false)}
            disabledInput={disabledInputCheck("submitterPhone", disabledInputs)}
            disabledInputMessage={
              disabledInputCheck("submitterPhone", disabledInputs) === false
                ? getInputMessage("submitterPhone", invalidInputsData, "10")
                : ""
            }
            invalidData={getInvalidData(
              "submitterPhone",
              invalidInputsData,
              "10",
            )}
          />
          <EmailField
            idProp="submitterEmail"
            label="Your Email"
            state={slide10}
            setState={setSlide10}
            val={slide10.submitterEmail}
            debouncedSave={slide10Mutation}
            setIsLoading={setIsLoading}
            autoData={predictiveTextFetched ? emails : []}
            req={!isValidEmail(slide10.submitterEmail, false)}
            disabledInput={disabledInputCheck("submitterEmail", disabledInputs)}
            disabledInputMessage={
              disabledInputCheck("submitterEmail", disabledInputs) === false
                ? getInputMessage("submitterEmail", invalidInputsData, "10")
                : ""
            }
            invalidData={getInvalidData(
              "submitterEmail",
              invalidInputsData,
              "10",
            )}
          /> */}
          {/* <hr className="add-hr" /> */}
          {/* <h1 id="add-h1">Additional Information</h1> */}
          {/* <hr className="add-hr" /> */}
          <p className="general-info">
            Is there any additional information you would like Argus to know
            about this project?
          </p>
          <div className={"form-slides"}>
            <textarea
              className="additional-info"
              aria-label="Additional Information Text Area"
              id="additionalInformation"
              type="text"
              rows="3"
              value={slide10.additionalInformation}
              onChange={handleTextAreaChange}
              disabled={
                requestStatus !== "IP" ||
                (requestStatus === "RV" &&
                  disabledInputCheck(
                    "additionalInformation",
                    disabledInputs,
                  ) === true)
              }
            ></textarea>
          </div>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};

export default AdditionalInformation;
