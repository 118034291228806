import React, { useContext, useEffect, useState } from "react";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";
import {
  isValidEmail,
  isValidName,
  isValidPhoneNumber,
} from "../Helper/formbtnhelper";
import EmailField from "../Inputs/EmailField";
import PhoneField from "../Inputs/PhoneField";
import TextField from "../Inputs/TextField";
import TitleField from "../Inputs/TitleField";
import { FormContext } from "../context/FormProvider";
import { scrollToFirstRequiredField } from "../main-helper";
import useSetSlide from "./Hooks/setSlide";
import useInvalidInputs from "./Hooks/useInvalidInputs";

// Slide 8: General Contractor/Operator Information
const GCInformation = ({ setSlideStatus, slideState, formStateLoaded }) => {
  const [disabledInputs, setDisabledInputs] = useState([]);
  const {
    slide1,
    slide7,
    slide8,
    setSlide8,
    setIsLoading,
    requestStatus,
    names,
    phoneNumbers,
    emails,
    titles,
    companies,
    regulatoryAgencies,
    predictiveTextFetched,
    populateInvalidInputs,
    getInvalidData,
    getInputMessage,
    disabledInputCheck,
    disableAll,
  } = useContext(FormContext);

  const { clientId, projectId } = useContext(DashboardContext);

  // ^ On Mount, the page should scroll to the top
  useEffect(() => {
    scrollToFirstRequiredField(slide8);
  }, []);

  // : MAIN QUERY LOGIC :

  // const {
  //   data: slide8Data,
  //   isLoading: slide8Loading,
  //   isError: slide8Error,
  //   error: slide8ErrorData,
  //   isFetching: slide8IsFetching,
  //   isFetched: slide8IsFetched,
  // } = useSlideState(clientId, projectId, 8);

  const slide8Mutation = useSetSlide(
    clientId,
    projectId,
    8,
    setIsLoading,
    setSlideStatus,
  );

  // useEffect(() => {
  //   if (!slide8IsFetching && !isLoading) {
  //     setTimeStamp(() => slide8Data.timeStamp);
  //   }
  // }, [slide8IsFetching, isLoading]);

  useEffect(() => {
    let newState = {};
    if (slide7.ownerGCCheck === true && slide7.oName !== slide8.gcName) {
      newState = {
        ...slide8,
        gcName: slide7.oName,
        gcAdd: slide7.oAdd,
        gcCity: slide7.oCity,
        gcZip: slide7.oZip,
        gcPhone: slide7.oPhone,
      };
      setSlide8(() => newState);
      slide8Mutation.mutate(newState);
      return;
    } else if (
      slide7.ownerGCCheck === false &&
      slide7.oName === slide8.gcName
    ) {
      newState = {
        ...slide8,
        gcName: "",
        gcAdd: "",
        gcCity: "",
        gcZip: "",
        gcPhone: "",
      };
      setSlide8(() => newState);
      slide8Mutation.mutate(newState);
      return;
    }
  }, [slide7.ownerGCCheck]);

  useEffect(() => {
    if (disableAll) return;
    if (slide7.ownerGCCheck === true) return;
    if (slide8.gcName === "" || slide8.gcName.length < 4) return;
    console.log("Reg Agents: ", regulatoryAgencies);
    for (const agency in regulatoryAgencies) {
      if (agency === slide8.gcName) {
        let newState = {
          ...slide8,
          gcPhone: regulatoryAgencies[agency].phone,
          gcAdd: regulatoryAgencies[agency].address,
          gcCity: regulatoryAgencies[agency].city,
          gcZip: regulatoryAgencies[agency].zip,
        };
        setSlide8(newState);
        slide8Mutation.mutate(newState);
      }
    }
  }, [slide8.gcName]);

  // : INVALID INPUTS LOGIC :

  const invalidInputsData = useInvalidInputs(
    clientId,
    projectId,
    requestStatus,
  );

  useEffect(() => {
    populateInvalidInputs(
      invalidInputsData,
      disabledInputs,
      setDisabledInputs,
      "8",
    );
  }, [invalidInputsData.data, invalidInputsData.isFetched]);

  return (
    <>
      {formStateLoaded ? (
        <>
          <p className="general-info">
            Please provide the following information about this projects primary
            Operator/General Contractor. All fields should match exactly as
            registered with the AZCC when forming the entity
          </p>
          <TextField
            idProp="gcName"
            label="Company Name"
            state={slide8}
            setState={setSlide8}
            val={slide8.gcName}
            debouncedSave={slide8Mutation}
            type="company"
            setIsLoading={setIsLoading}
            autoData={predictiveTextFetched ? companies : []}
            req={slide8.gcName === "" || slide8.gcName.length < 3}
            disabledInput={disabledInputCheck("gcName", disabledInputs)}
            disabledInputMessage={
              disabledInputCheck("gcName", disabledInputs) === false
                ? getInputMessage("gcName", invalidInputsData, "8")
                : ""
            }
            invalidData={getInvalidData("gcName", invalidInputsData, "8")}
          />
          <TextField
            idProp="gcAdd"
            label="Street Address"
            state={slide8}
            setState={setSlide8}
            val={slide8.gcAdd}
            debouncedSave={slide8Mutation}
            setIsLoading={setIsLoading}
            req={slide8.gcAdd === "" || slide8.gcAdd.length < 6}
            disabledInput={disabledInputCheck("gcAdd", disabledInputs)}
            disabledInputMessage={
              disabledInputCheck("gcAdd", disabledInputs) === false
                ? getInputMessage("gcAdd", invalidInputsData, "8")
                : ""
            }
            invalidData={getInvalidData("gcAdd", invalidInputsData, "8")}
          />
          <TextField
            idProp="gcCity"
            label="City"
            state={slide8}
            setState={setSlide8}
            val={slide8.gcCity}
            debouncedSave={slide8Mutation}
            setIsLoading={setIsLoading}
            req={slide8.gcCity === "" || slide8.gcCity.length < 3}
            disabledInput={disabledInputCheck("gcCity", disabledInputs)}
            disabledInputMessage={
              disabledInputCheck("gcCity", disabledInputs) === false
                ? getInputMessage("gcCity", invalidInputsData, "8")
                : ""
            }
            invalidData={getInvalidData("gcCity", invalidInputsData, "8")}
          />
          <TextField
            idProp="gcZip"
            label="Zip"
            state={slide8}
            setState={setSlide8}
            val={slide8.gcZip}
            debouncedSave={slide8Mutation}
            setIsLoading={setIsLoading}
            req={slide8.gcZip === "" || slide8.gcZip.length !== 5}
            disabledInput={disabledInputCheck("gcZip", disabledInputs)}
            disabledInputMessage={
              disabledInputCheck("gcZip", disabledInputs) === false
                ? getInputMessage("gcZip", invalidInputsData, "8")
                : ""
            }
            invalidData={getInvalidData("gcZip", invalidInputsData, "8")}
          />
          <PhoneField
            idProp="gcPhone"
            label="Office Phone"
            state={slide8}
            setState={setSlide8}
            val={slide8.gcPhone}
            debouncedSave={slide8Mutation}
            setIsLoading={setIsLoading}
            req={!isValidPhoneNumber(slide8.gcPhone, false)}
            autoData={predictiveTextFetched ? phoneNumbers : []}
            disabledInput={disabledInputCheck("gcPhone", disabledInputs)}
            disabledInputMessage={
              disabledInputCheck("gcPhone", disabledInputs) === false
                ? getInputMessage("gcPhone", invalidInputsData, "8")
                : ""
            }
            invalidData={getInvalidData("gcPhone", invalidInputsData, "8")}
          />
          {+slide1.distAcr >= 1 ? (
            <>
              <p className="general-info">
                Please provide the following information for the individual who
                has the authority to make legal decisions and act on behalf of
                the Operator/General Contractor. This individual will be
                certifying the NOI.
              </p>
              <TextField
                idProp="gcContactName"
                label="GC's Responsible Officer"
                state={slide8}
                setState={setSlide8}
                val={slide8.gcContactName}
                debouncedSave={slide8Mutation}
                type="name"
                setIsLoading={setIsLoading}
                req={!isValidName(slide8.gcContactName, false)}
                autoData={predictiveTextFetched ? names : []}
                disabledInput={disabledInputCheck(
                  "gcContactName",
                  disabledInputs,
                )}
                disabledInputMessage={
                  disabledInputCheck("gcContactName", disabledInputs) === false
                    ? getInputMessage("gcContactName", invalidInputsData, "8")
                    : ""
                }
                invalidData={getInvalidData(
                  "gcContactName",
                  invalidInputsData,
                  "8",
                )}
              />
              <TitleField
                idProp="gcContactTitle"
                label="Responsible Officer's Title"
                state={slide8}
                setState={setSlide8}
                val={slide8.gcContactTitle}
                debouncedSave={slide8Mutation}
                type="title"
                setIsLoading={setIsLoading}
                req={
                  slide8.gcContactTitle === "" ||
                  slide8.gcContactTitle.length < 3
                }
                autoData={predictiveTextFetched ? titles : []}
                disabledInput={disabledInputCheck(
                  "gcContactTitle",
                  disabledInputs,
                )}
                disabledInputMessage={
                  disabledInputCheck("gcContactTitle", disabledInputs) === false
                    ? getInputMessage("gcContactTitle", invalidInputsData, "8")
                    : ""
                }
                invalidData={getInvalidData(
                  "gcContactTitle",
                  invalidInputsData,
                  "8",
                )}
              />
              <PhoneField
                idProp="gcContactPhone"
                label="Responsible Officer's Phone"
                state={slide8}
                setState={setSlide8}
                val={slide8.gcContactPhone}
                debouncedSave={slide8Mutation}
                setIsLoading={setIsLoading}
                req={!isValidPhoneNumber(slide8.gcContactPhone, false)}
                autoData={predictiveTextFetched ? phoneNumbers : []}
                disabledInput={disabledInputCheck(
                  "gcContactPhone",
                  disabledInputs,
                )}
                disabledInputMessage={
                  disabledInputCheck("gcContactPhone", disabledInputs) === false
                    ? getInputMessage("gcContactPhone", invalidInputsData, "8")
                    : ""
                }
                invalidData={getInvalidData(
                  "gcContactPhone",
                  invalidInputsData,
                  "8",
                )}
              />
              <EmailField
                idProp="gcContactEmail"
                label="Responsible Officer's Email"
                state={slide8}
                setState={setSlide8}
                val={slide8.gcContactEmail}
                debouncedSave={slide8Mutation}
                setIsLoading={setIsLoading}
                req={!isValidEmail(slide8.gcContactEmail, false)}
                autoData={predictiveTextFetched ? emails : []}
                disabledInput={disabledInputCheck(
                  "gcContactEmail",
                  disabledInputs,
                )}
                disabledInputMessage={
                  disabledInputCheck("gcContactEmail", disabledInputs) === false
                    ? getInputMessage("gcContactEmail", invalidInputsData, "8")
                    : ""
                }
                invalidData={getInvalidData(
                  "gcContactEmail",
                  invalidInputsData,
                  "8",
                )}
              />
              <p className="general-info">
                Please provide the following information for the individual in
                charge of day-to-day operations, and has been delegated
                authority to act on behalf of the responsible officer.
              </p>
              <TextField
                idProp="gcContact2Name"
                label="Day-to-Day Project Contact"
                state={slide8}
                setState={setSlide8}
                val={slide8.gcContact2Name}
                debouncedSave={slide8Mutation}
                type="name"
                setIsLoading={setIsLoading}
                req={!isValidName(slide8.gcContact2Name, false)}
                autoData={predictiveTextFetched ? names : []}
                disabledInput={disabledInputCheck(
                  "gcContact2Name",
                  disabledInputs,
                )}
                disabledInputMessage={
                  disabledInputCheck("gcContact2Name", disabledInputs) === false
                    ? getInputMessage("gcContact2Name", invalidInputsData, "8")
                    : ""
                }
                invalidData={getInvalidData(
                  "gcContact2Name",
                  invalidInputsData,
                  "8",
                )}
              />
              <TitleField
                idProp="gcContact2Title"
                label="Contact's Title"
                state={slide8}
                setState={setSlide8}
                val={slide8.gcContact2Title}
                debouncedSave={slide8Mutation}
                type="title"
                setIsLoading={setIsLoading}
                req={
                  slide8.gcContact2Title === "" ||
                  slide8.gcContact2Title.length < 3
                }
                autoData={predictiveTextFetched ? titles : []}
                disabledInput={disabledInputCheck(
                  "gcContact2Title",
                  disabledInputs,
                )}
                disabledInputMessage={
                  disabledInputCheck("gcContact2Title", disabledInputs) ===
                  false
                    ? getInputMessage("gcContact2Title", invalidInputsData, "8")
                    : ""
                }
                invalidData={getInvalidData(
                  "gcContact2Title",
                  invalidInputsData,
                  "8",
                )}
              />
              <PhoneField
                idProp="gcContact2Phone"
                label="Contact's Phone"
                state={slide8}
                setState={setSlide8}
                val={slide8.gcContact2Phone}
                debouncedSave={slide8Mutation}
                setIsLoading={setIsLoading}
                req={!isValidPhoneNumber(slide8.gcContact2Phone, false)}
                autoData={predictiveTextFetched ? phoneNumbers : []}
                disabledInput={disabledInputCheck(
                  "gcContact2Phone",
                  disabledInputs,
                )}
                disabledInputMessage={
                  disabledInputCheck("gcContact2Phone", disabledInputs) ===
                  false
                    ? getInputMessage("gcContact2Phone", invalidInputsData, "8")
                    : ""
                }
                invalidData={getInvalidData(
                  "gcContact2Phone",
                  invalidInputsData,
                  "8",
                )}
              />
              <EmailField
                idProp="gcContact2Email"
                label="Contact's Email"
                state={slide8}
                setState={setSlide8}
                val={slide8.gcContact2Email}
                debouncedSave={slide8Mutation}
                setIsLoading={setIsLoading}
                req={!isValidEmail(slide8.gcContact2Email, false)}
                autoData={predictiveTextFetched ? emails : []}
                disabledInput={disabledInputCheck(
                  "gcContact2Email",
                  disabledInputs,
                )}
                disabledInputMessage={
                  disabledInputCheck("gcContact2Email", disabledInputs) ===
                  false
                    ? getInputMessage("gcContact2Email", invalidInputsData, "8")
                    : ""
                }
                invalidData={getInvalidData(
                  "gcContact2Email",
                  invalidInputsData,
                  "8",
                )}
              />
              <p className="general-info">
                Please provide the following information for the individual in
                charge of field operations.
              </p>
              <TextField
                idProp="gcContact3Name"
                label="Field Operation Contact"
                state={slide8}
                setState={setSlide8}
                val={slide8.gcContact3Name}
                debouncedSave={slide8Mutation}
                type="name"
                setIsLoading={setIsLoading}
                req={!isValidName(slide8.gcContact3Name, true)}
                autoData={predictiveTextFetched ? names : []}
                disabledInput={disabledInputCheck(
                  "gcContact3Name",
                  disabledInputs,
                )}
                disabledInputMessage={
                  disabledInputCheck("gcContact3Name", disabledInputs) === false
                    ? getInputMessage("gcContact3Name", invalidInputsData, "8")
                    : ""
                }
                invalidData={getInvalidData(
                  "gcContact3Name",
                  invalidInputsData,
                  "8",
                )}
              />
              <TitleField
                idProp="gcContact3Title"
                label="Contact's Title"
                state={slide8}
                setState={setSlide8}
                val={slide8.gcContact3Title}
                debouncedSave={slide8Mutation}
                type="title"
                setIsLoading={setIsLoading}
                req={false}
                autoData={predictiveTextFetched ? titles : []}
                disabledInput={disabledInputCheck(
                  "gcContact3Title",
                  disabledInputs,
                )}
                disabledInputMessage={
                  disabledInputCheck("gcContact3Title", disabledInputs) ===
                  false
                    ? getInputMessage("gcContact3Title", invalidInputsData, "8")
                    : ""
                }
                invalidData={getInvalidData(
                  "gcContact3Title",
                  invalidInputsData,
                  "8",
                )}
              />
              <PhoneField
                idProp="gcContact3Phone"
                label="Contact's Phone"
                state={slide8}
                setState={setSlide8}
                val={slide8.gcContact3Phone}
                debouncedSave={slide8Mutation}
                setIsLoading={setIsLoading}
                req={!isValidPhoneNumber(slide8.gcContact3Phone, true)}
                autoData={predictiveTextFetched ? phoneNumbers : []}
                disabledInput={disabledInputCheck(
                  "gcContact3Phone",
                  disabledInputs,
                )}
                disabledInputMessage={
                  disabledInputCheck("gcContact3Phone", disabledInputs) ===
                  false
                    ? getInputMessage("gcContact3Phone", invalidInputsData, "8")
                    : ""
                }
                invalidData={getInvalidData(
                  "gcContact3Phone",
                  invalidInputsData,
                  "8",
                )}
              />
              <EmailField
                idProp="gcContact3Email"
                label="Contact's Email"
                state={slide8}
                setState={setSlide8}
                val={slide8.gcContact3Email}
                debouncedSave={slide8Mutation}
                setIsLoading={setIsLoading}
                req={!isValidEmail(slide8.gcContact3Email, true)}
                autoData={predictiveTextFetched ? emails : []}
                disabledInput={disabledInputCheck(
                  "gcContact3Email",
                  disabledInputs,
                )}
                disabledInputMessage={
                  disabledInputCheck("gcContact3Email", disabledInputs) ===
                  false
                    ? getInputMessage("gcContact3Email", invalidInputsData, "8")
                    : ""
                }
                invalidData={getInvalidData(
                  "gcContact3Email",
                  invalidInputsData,
                  "8",
                )}
              />
            </>
          ) : (
            <>
              <TextField
                idProp="projectManager"
                label="Project Manager"
                state={slide8}
                setState={setSlide8}
                val={slide8.projectManager}
                debouncedSave={slide8Mutation}
                type="name"
                setIsLoading={setIsLoading}
                req={!isValidName(slide8.projectManager, false)}
                autoData={predictiveTextFetched ? names : []}
                disabledInput={disabledInputCheck(
                  "projectManager",
                  disabledInputs,
                )}
                disabledInputMessage={
                  disabledInputCheck("projectManager", disabledInputs) === false
                    ? getInputMessage("projectManager", invalidInputsData, "8")
                    : ""
                }
                invalidData={getInvalidData(
                  "projectManager",
                  invalidInputsData,
                  "8",
                )}
              />
              <PhoneField
                idProp="projectManagerPhone"
                label="Project Manager's Phone"
                state={slide8}
                setState={setSlide8}
                val={slide8.projectManagerPhone}
                debouncedSave={slide8Mutation}
                setIsLoading={setIsLoading}
                req={!isValidPhoneNumber(slide8.projectManagerPhone, false)}
                autoData={predictiveTextFetched ? phoneNumbers : []}
                disabledInput={disabledInputCheck(
                  "projectManagerPhone",
                  disabledInputs,
                )}
                disabledInputMessage={
                  disabledInputCheck("projectManagerPhone", disabledInputs) ===
                  false
                    ? getInputMessage(
                        "projectManagerPhone",
                        invalidInputsData,
                        "8",
                      )
                    : ""
                }
                invalidData={getInvalidData(
                  "projectManagerPhone",
                  invalidInputsData,
                  "8",
                )}
              />
              <EmailField
                idProp="projectManagerEmail"
                label="Project Manager's Email"
                state={slide8}
                setState={setSlide8}
                val={slide8.projectManagerEmail}
                debouncedSave={slide8Mutation}
                setIsLoading={setIsLoading}
                req={!isValidEmail(slide8.projectManagerEmail, false)}
                autoData={predictiveTextFetched ? emails : []}
                disabledInput={disabledInputCheck(
                  "projectManagerEmail",
                  disabledInputs,
                )}
                disabledInputMessage={
                  disabledInputCheck("projectManagerEmail", disabledInputs) ===
                  false
                    ? getInputMessage(
                        "projectManagerEmail",
                        invalidInputsData,
                        "8",
                      )
                    : ""
                }
                invalidData={getInvalidData(
                  "projectManagerEmail",
                  invalidInputsData,
                  "8",
                )}
              />
              <TextField
                idProp="superintendent"
                label="Superintendent"
                state={slide8}
                setState={setSlide8}
                val={slide8.superintendent}
                debouncedSave={slide8Mutation}
                type="name"
                setIsLoading={setIsLoading}
                req={!isValidName(slide8.superintendent, false)}
                autoData={predictiveTextFetched ? names : []}
                disabledInput={disabledInputCheck(
                  "superintendent",
                  disabledInputs,
                )}
                disabledInputMessage={
                  disabledInputCheck("superintendent", disabledInputs) === false
                    ? getInputMessage("superintendent", invalidInputsData, "8")
                    : ""
                }
                invalidData={getInvalidData(
                  "superintendent",
                  invalidInputsData,
                  "8",
                )}
              />
              <PhoneField
                idProp="superintendentPhone"
                label="Superintendent's Phone"
                state={slide8}
                setState={setSlide8}
                val={slide8.superintendentPhone}
                debouncedSave={slide8Mutation}
                setIsLoading={setIsLoading}
                req={!isValidPhoneNumber(slide8.superintendentPhone, false)}
                autoData={predictiveTextFetched ? phoneNumbers : []}
                disabledInput={disabledInputCheck(
                  "superintendentPhone",
                  disabledInputs,
                )}
                disabledInputMessage={
                  disabledInputCheck("superintendentPhone", disabledInputs) ===
                  false
                    ? getInputMessage(
                        "superintendentPhone",
                        invalidInputsData,
                        "8",
                      )
                    : ""
                }
                invalidData={getInvalidData(
                  "superintendentPhone",
                  invalidInputsData,
                  "8",
                )}
              />
              <EmailField
                idProp="superintendentEmail"
                label="Superintendent's Email"
                state={slide8}
                setState={setSlide8}
                val={slide8.superintendentEmail}
                debouncedSave={slide8Mutation}
                setIsLoading={setIsLoading}
                req={!isValidEmail(slide8.superintendentEmail, false)}
                autoData={predictiveTextFetched ? emails : []}
                disabledInput={disabledInputCheck(
                  "superintendentEmail",
                  disabledInputs,
                )}
                disabledInputMessage={
                  disabledInputCheck("superintendentEmail", disabledInputs) ===
                  false
                    ? getInputMessage(
                        "superintendentEmail",
                        invalidInputsData,
                        "8",
                      )
                    : ""
                }
                invalidData={getInvalidData(
                  "superintendentEmail",
                  invalidInputsData,
                  "8",
                )}
              />
            </>
          )}
        </>
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};

export default GCInformation;
