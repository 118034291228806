import { useQuery } from "@tanstack/react-query";
import axios from "axios";

// Function to fetch invalid inputs by clientId and projectId
export const fetchInvalidInputs = async (clientId, projectId) => {
  const invalidInputs = await axios.get(
    `/api/invalid-inputs/${clientId}/${projectId}/`,
    {
      withCredentials: true,
    },
  );

  return invalidInputs.data;
};

// Custom hook to fetch invalid inputs
export default function useInvalidInputs(clientId, projectId, requestStatus) {
  return useQuery({
    queryKey: ["invalid-inputs", clientId, projectId],
    queryFn: () => fetchInvalidInputs(clientId, projectId),
    enabled: !!clientId && !!projectId && requestStatus === "RV",
  });
}
