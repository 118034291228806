import ClearIcon from "@mui/icons-material/Clear";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import {
  clearClient,
  setClient,
  setClientID,
  setProjectID,
} from "../../../../../Redux/Actions/actions";
import useAdminClients from "../../../Admin/AdminQueries/useAdminClients";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";
import "../styles/form-container.css";
import "./styles/intro-slide.css";

// Slide 0: Introduction
const IntoSlide = () => {
  const dispatch = useDispatch();
  const [selectedClient, setSelectedClient] = useState(null);

  const { queryClient, isAdmin } = useContext(DashboardContext);

  // + GET ALL CLIENTS FOR SELECT
  const { data: clientsData, isFetched: clientsFetched } =
    useAdminClients(isAdmin);

  return (
    <>
      {isAdmin && clientsFetched ? (
        <FormControl
          id={"clientSelectForm"}
          classes={{ root: "clientSelectFormRoot" }}
        >
          <InputLabel
            id={`${"clientSelectLabel"}`}
            classes={{ shrink: "labelShrink" }}
            htmlFor={`${"clientSelect"}`}
          >
            Select a Client
          </InputLabel>
          <Select
            id={`${"clientSelect"}`}
            className={"clientSelect"}
            variant="standard"
            value={selectedClient}
            SelectDisplayProps={{
              className: "clientSelectDisplay",
            }}
            MenuProps={{
              className: "clientSelectMenu",
              classes: {
                paper: "clientSelectPaper",
                list: "clientSelectList",
              },
            }}
            onChange={(e) => {
              if (e.target.value === 0) {
                dispatch(setClientID(null));
                dispatch(setProjectID(null));
                dispatch(clearClient());
                setSelectedClient(null);
                queryClient.invalidateQueries({ queryKey: "adminClients" });
              } else {
                dispatch(setClientID(e.target.value));
                dispatch(
                  setClient(
                    clientsData.filter((c) => c.id === e.target.value)[0],
                  ),
                );
                setSelectedClient(e.target.value);
                queryClient.invalidateQueries({
                  queryKey: ["adminClients", isAdmin, e.target.value],
                });
              }
            }}
            classes={{
              root: "clientSelectRoot",
              select: "clientSelectRoot-select",
              icon: "clientSelectIcon",
              nativeInput: "clientSelectNativeInput",
            }}
          >
            <MenuItem
              key={"clear-select"}
              className={"menuItemIntro"}
              id={"clearMenuItems"}
              classes={{
                root: "clientLI",
                selected: "clientLISelected",
                divider: "clientLIDivider",
              }}
              value={0}
              divider
            >
              <span>Clear</span>
              <ClearIcon id={"menuClearIcon"} />
            </MenuItem>
            {isAdmin &&
              clientsFetched &&
              clientsData.map((client, i) => (
                <MenuItem
                  key={`${client.name}-${i}`}
                  className={"menuItemIntro"}
                  classes={{
                    root: "clientLI",
                    selected: "clientLISelected",
                    divider: "clientLIDivider",
                  }}
                  value={client.id}
                >
                  {client.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      ) : isAdmin && !clientsData ? (
        <p className={"loadingP"}>Loading...</p>
      ) : null}
    </>
  );
};

export default IntoSlide;
